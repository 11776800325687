<template>
  <b-modal
    id="payment-refund-modal"
    ref="payment-refund-modal"
    title="Refund method"
    centered
    ok-title="Ok"
    ok-only
    size="sm"
    @hidden="resetModal"
    @show="setOptions"
  >
    <ValidationObserver
      ref="refundForm"
    >
      <b-card-text class="mb-2 text-left">
        <b-col
          v-if="transaction.type === 'Credit Card'"
          sm="12"
          class="mb-1"
        >
          <b-form-group
            class="mb-2 text-left"
          >
            <b-form-radio
              v-model="selectedMethod"
              name="some-radios"
              value="creditCard"
              class="mb-1"
            >
              Make a refund on the Credit Card
            </b-form-radio>
            <b-form-radio
              v-model="selectedMethod"
              name="some-radios"
              value="cashBank"
            >
              Add to Cash Bank
            </b-form-radio>
          </b-form-group>
        </b-col>

        <b-col
          v-else
          sm="12"
          class="mb-1"
        >
          <b-card-text class="mb-2">
            Funds will not come to the Credit Card
          </b-card-text>
          <!-- All Day -->
          <b-form-group>
            <b-form-checkbox
              v-model="addToCashBank"
              name="check-button"
              switch
              inline
            >
              Add to Cash Bank
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            label="Refund Amount"
          >
            <validation-provider
              #default="{ errors }"
              name="refund amount"
              :rules="{
                required: true,
                max_value: maxAmount
              }"
            >
              <b-form-input
                v-model="amount"
                type="number"
                :class="{'is-invalid': errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          v-if="transaction.type !== 'Credit Card'"
          sm="12"
        >
          <validation-provider
            #default="{ errors }"
            name="Refund Date"
            rules="required"
          >
            <b-form-group
              label="Refund Date"
            >
              <flat-pickr
                v-model="refundDate"
                class="form-control"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col
          v-if="transaction.type !== 'Credit Card'"
          sm="12"
        >
          <b-form-group
            label="Notes"
          >
            <b-form-textarea
              v-model="notes"
              placeholder="Add Refund Notes"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="transaction.type === 'Credit Card'"
          sm="12"
        >
          <b-form-group
            label="Reason"
          >
            <validation-provider
              #default="{ errors }"
              name="Refund Reason"
              rules="required"
            >
              <b-form-textarea
                v-model="notes"
                placeholder="Add Refund Reason"
                rows="3"
                :class="{'is-invalid': errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-card-text>
    </ValidationObserver>

    <template #modal-footer>
      <b-button
        variant="primary"
        class="float-right"
        @click="handleForm"
      >
        Ok
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BCardText,
  BFormRadio,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max_value } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import moment from '@/libs/moment'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormRadio,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    BFormTextarea,

    flatPickr,
  },
  props: {
    transaction: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedMethod: 'creditCard',
      addToCashBank: false,
      refundDate: new Date(),
      notes: '',
      amount: 0,
      required,
      max_value,
    }
  },
  computed: {
    maxAmount() {
      if (this.transaction?.refundable_sum) {
        return Math.abs(this.transaction.refundable_sum)
      }
      return 99999
    },
  },
  methods: {
    setOptions() {
      this.amount = Math.abs(this.transaction?.refundable_sum)
    },
    async handleForm() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs.refundForm.validate().then(success => {
          if (success) {
            resolve(true)
            this.sendData()
          } else {
            reject()
          }
        })
      })
    },
    async sendData() {
      let isCashBank = false
      if (this.transaction.type === 'Credit Card') {
        isCashBank = this.selectedMethod === 'cashBank'
      } else {
        isCashBank = this.addToCashBank
      }
      const data = {
        is_cashbank: isCashBank,
        amount: this.amount,
      }

      if (this.transaction.type !== 'Credit Card') {
        data.refund_date = this.prepareDateToUtc(this.refundDate)
        data.notes = this.notes
      }

      if (this.transaction.type === 'Credit Card') {
        data.notes = this.notes
      }

      this.$emit('acceptRefund', data)
      this.closeModal()
    },
    closeModal() {
      this.$refs['payment-refund-modal'].hide()
    },
    resetModal() {
      this.selectedMethod = 'creditCard'
      this.amount = 0
      this.notes = ''
    },
    prepareDateToUtc(date) {
      return moment.utc(moment(date).format('YYYY-MM-DDTHH:mmZ'))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
