<template>
  <b-card
    no-body
  >

    <b-form>
      <b-row>

        <b-col
          sm="12"
          class="d-flex align-items-center mb-2"
        >
          <feather-icon
            icon="UserIcon"
            size="16"
          />
          <h5 class="mb-0 ml-75 font-weight-normal">
            Parent Info
          </h5>
        </b-col>

        <b-col sm="4">
          <b-form-group
            label="First Name"
          >
            <b-form-input
              v-model="userData.user.first_name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Last Name"
          >
            <b-form-input
              v-model="userData.user.last_name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Phone Number"
          >
            <b-form-input
              v-model="userData.user.phone"
            />
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group
            label="Gender"
          >
            <div
              class="d-flex"
            >
              <b-form-radio
                v-model="userData.user.extra_user_info.gender"
                name="some-radios"
                value="Male"
              >
                Male
              </b-form-radio>
              <b-form-radio
                v-model="userData.user.extra_user_info.gender"
                name="some-radios"
                value="Female"
                class="ml-2"
              >
                Female
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group
            label="Verified Shliach"
          >
            <b-form-checkbox
              v-model="userData.is_shliach_member"
              :value="true"
              class="custom-control-primary"
            >
              true
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group
            label="Approved"
          >
            <b-form-checkbox
              v-model="userData.is_approved"
              :value="true"
              class="custom-control-primary"
            >
              true
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group
            label="Email"
          >
            <b-form-input
              v-model="userData.user.email"
            />
          </b-form-group>
        </b-col>

        <b-col
          sm="12"
          class="mt-1 d-flex align-items-center mb-2"
        >
          <feather-icon
            icon="MapPinIcon"
            size="16"
          />
          <h5 class="mb-0 ml-75 font-weight-normal">
            Address
          </h5>
        </b-col>

        <b-col sm="4">
          <b-form-group
            label="Address Line 1"
          >
            <b-form-input
              v-model="userData.user.address.address_line_1"
              placeholder="Address Line 1"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Address Line 2"
          >
            <b-form-input
              v-model="userData.user.address.address_line_2"
              placeholder="Address Line 2"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Postcode"
          >
            <b-form-input
              v-model="userData.user.address.zip"
              placeholder="Postcode"
            />
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group
            label="City"
          >
            <b-form-input
              v-model="userData.user.address.city"
              placeholder="City"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="State"
          >
            <b-form-input
              v-model="userData.user.address.state"
              placeholder="State"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Country"
          >
            <b-form-input
              v-model="userData.user.address.country"
              placeholder="Country"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <div
            v-if="validationErrors.length"
            class="form-group pt-1"
          >
            <p
              v-for="(validationError, index) in validationErrors"
              :key="index"
              class="text-center text-danger"
            >
              {{ validationError }}
            </p>
          </div>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            :disabled="!$can('update', permissionSubjects.Parent)"
            @click="handleForm"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            type="reset"
            class="mt-2"
            :to="{ name: 'admin-parents'}"
            @click.prevent="resetForm"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-form>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
  BButton, BForm, BFormGroup, BFormInput, BFormRadio, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormCheckbox,
    BButton,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    validationErrors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      permissionSubjects,
    }
  },
  methods: {
    handleForm() {
      this.$emit('updParentInfo')
    },
  },
}
</script>

<style>

</style>
