import { ref } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTransactions() {
  // Use toast
  const toast = useToast()
  const refTransactionsTable = ref(null)
  const infoModalName = 'bulkChangeStatus'

  const cancelCharge = ({ id, queryParams }) => axios
    .post(`/auth/charges/${id}/cancel`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success canceling charge',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error canceling Charge',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const deleteCharge = (id, queryParams) => axios
    .post(`/auth/charges/${id}/delete`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting charge',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error deleting Charge',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const undoCancelCharge = id => axios
    .post(`/auth/charges/${id}/undo-cancel`)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success undo canceling charge',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error undo canceling Charge',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateChargeNotes = (id, queryParams) => axios
    .post(`/auth/charges/${id}/update-notes`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success updating charge notes',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error undo canceling Charge',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateTransactionNotes = (id, queryParams) => axios
    .post(`/auth/transactions/${id}/update-notes`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success updating notes',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error undo updating notes',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const getTransaction = id => axios
    .get(`/auth/transactions/${id}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Transaction',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateTransaction = (id, queryParams) => axios
    .put(`/auth/transactions/${id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success updating Transaction',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error editing Transaction',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const retryTransaction = id => axios
    .post(`/auth/transactions/${id}/retry`)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success retrying Transaction',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error retrying Transaction',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const refundTransaction = (id, queryParams) => axios
    .post(`/auth/transactions/${id}/refund`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success refunding Payment',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error refunding Payment',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const deletePayCondition = id => axios
    .delete(`/auth/application-pay-conditions/${id}`)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success destroyed Application Pay Condition',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Payment error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const deleteTransaction = id => axios
    .delete(`/auth/transactions/${id}`)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleted Transaction',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const fetchPaymentMethods = queryParams => axios
    .get('/auth/payment-methods', { params: queryParams })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Payment Methods',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const fetchTransactionStatuses = queryParams => axios
    .get('/auth/transaction-statuses', { params: queryParams })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Transaction Statuses',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const bulkDeleteTransactions = queryParams => axios
    .post('/auth/transactions/bulk-actions/delete', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting transactions',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error deleting transactions',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const exportFamilyReport = id => axios
    .get(`/auth/applications/${id}/export-family-report`, { responseType: 'blob' })
    .then(response => response)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error exporting pdf',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const exportTransactionsCSV = queryParams => axios
    .post('/auth/transactions/export', queryParams)
    .then(response => response)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error exporting Transactions',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  return {
    cancelCharge,
    deleteCharge,
    undoCancelCharge,
    updateChargeNotes,
    updateTransactionNotes,
    getTransaction,
    updateTransaction,
    retryTransaction,
    refundTransaction,
    deletePayCondition,
    deleteTransaction,
    fetchPaymentMethods,
    fetchTransactionStatuses,
    refTransactionsTable,
    bulkDeleteTransactions,
    infoModalName,
    exportFamilyReport,
    exportTransactionsCSV,
  }
}
