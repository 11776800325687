<template>
  <b-dropdown
    id="visible-columns-dropdown"
    ref="visibleColumnsDropdown"
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    right
    variant="flat-primary"
    no-caret
    no-flip
    lazy
    @click.native.stop
    @show="setOptions"
  >
    <template #button-content>
      <feather-icon
        icon="EyeIcon"
      />
      <span class="pl-1">Visible Columns</span>
    </template>
    <b-dropdown-form>
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="mb-25 pr-4 font-weight-bolder">
          Visible Columns
        </h5>
      </div>

      <hr>
      <div class="visible-columns-options">
        <template
          v-for="column in columnsList"
        >
          <div
            v-if="column.key !== 'actions'"
            :key="column.key"
            class="mb-2"
          >
            <b-form-checkbox
              :id="column.key"
              v-model="column.selected"
            >
              {{ column.label }}
            </b-form-checkbox>
          </div>
        </template>
      </div>

      <hr>

      <div class="d-flex justify-content-between">
        <b-button
          variant="outline-primary"
          @click="cancel"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveSelectedColumns"
        >
          Save
        </b-button>
      </div>
    </b-dropdown-form>

  </b-dropdown>
</template>

<script>
import {
  BDropdown,
  BDropdownForm,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'VisibleColumnsDropdown',
  components: {
    BDropdown,
    BDropdownForm,
    BFormCheckbox,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columnsList: [],
    }
  },
  methods: {
    closeDropdown() {
      this.$refs.visibleColumnsDropdown.hide()
    },
    setOptions() {
      this.columnsList = JSON.parse(JSON.stringify(this.columns))
    },
    saveSelectedColumns() {
      this.$emit('saveVisibleColumns', this.columnsList)
      this.closeDropdown()
    },
    cancel() {
      this.closeDropdown()
    },
  },
}
</script>

<style lang="scss" scoped>
.visible-columns-options {
  width: 265px;
  max-height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-button {
      height: 1px;
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #D8D6DE;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #7367F0;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: darken(#7367F0, 5%);
    }
}

</style>
