<template>
  <ValidationObserver
    ref="applicationSelectionRules"
  >
    <b-modal
      id="application-selection-modal"
      ref="application-selection-modal"
      centered
      title="Select Student"
      ok-only
      no-close-on-backdrop
      @show="setModalData"
      @ok="handleForm"
    >
      <b-row>
        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            :label="`${camperStudent(2)} List`"
          >
            <validation-provider
              #default="{ errors }"
              :name="`${camperStudent(2)} List`"
              rules="required"
            >
              <v-select
                v-model="selectedApplication"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="applicationsList"
                :filterable="false"
                :clearable="false"
                :placeholder="applicationsFieldStatus"
                @search="onSearch"
              >
                <template #no-options>
                  Loading...
                </template>
                <li
                  v-if="metaData.current_page"
                  slot="list-footer"
                  class="pagination d-flex  align-items-center justify-content-between my-1 mx-2"
                >
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-primary"
                    :disabled="!hasPrevPage"
                    @click="currentPage--"
                  >
                    Prev
                  </b-button>
                  <span>{{ currentPage }} of {{ metaData.last_page }}</span>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-primary"
                    :disabled="!hasNextPage"
                    @click="currentPage++"
                  >
                    Next
                  </b-button>
                </li></v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import axios from '@axios'
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

let debounceTimeout = null

export default {
  name: 'ApplicationSelectionModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    programId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      applicationsFieldStatus: 'Loading...',
      selectedApplication: null,
      applicationsList: [],
      currentPage: 1,
      searchQuery: null,
      metaData: {},
      required,
    }
  },
  computed: {
    hasNextPage() {
      return this.metaData.current_page !== this.metaData.last_page
    },
    hasPrevPage() {
      return this.currentPage > 1
    },
  },
  watch: {
    currentPage() {
      this.setApplicationsList()
    },
    searchQuery(newVal, prevVal) {
      if (newVal !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          this.setApplicationsList()
        }, 250)
      }
    },
  },
  methods: {
    setModalData() {
      this.applicationsFieldStatus = 'Loading...'
      this.selectedApplication = null
      this.currentPage = 1
      this.searchQuery = null
      this.metaData = {}
      this.applicationsList = []
      this.setApplicationsList()
    },
    async setApplicationsList() {
      try {
        this.applicationsFieldStatus = 'Loading...'
        const queryParams = {
          userRole: 'Student',
          programId: this.programId,
          perPage: 10,
          page: this.currentPage,
        }
        if (this.searchQuery) queryParams.userName = this.searchQuery

        this.applicationsList = []

        const response = await axios.get('/auth/applications', { params: queryParams })
        this.applicationsList = response.data.data.map(item => ({
          applicationId: item.id,
          userId: item.user.id,
          label: item.user.full_name,
        }))
        this.metaData = response.data.meta
        return response
      } catch (error) {
        return error
      } finally {
        this.applicationsFieldStatus = `Select ${this.camperStudent(1)}`
      }
    },
    async onSearch(query) {
      this.searchQuery = query
      this.currentPage = 1
    },
    handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs.applicationSelectionRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.$emit('selectApplication', this.selectedApplication)
            this.$bvModal.hide('application-selection-modal')
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
