import axios from '@axios'

export default {
  namespaced: true,
  state: {
    deletableUser: null,
    deletableParent: null,
  },
  getters: {
    getDeletableUser: state => state.deletableUser,
    getDeletableParent: state => state.deletableParent,
  },
  mutations: {
    SET_DELETABLE_USER: (state, val) => {
      state.deletableUser = val
    },
    SET_DELETABLE_PARENT: (state, val) => {
      state.deletableParent = val
    },
  },
  actions: {
    fetchParentList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/parents', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchParentStatuses() {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/parent-statuses')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchParent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/auth/parents/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoices(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/auth/parents/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTransactionList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/transactions', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchApplicationList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/applications', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTaskList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/application-tasks', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteParent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/auth/parents/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
