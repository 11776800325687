<template>
  <b-modal
    id="resume-payments"
    ref="resume-payments"
    title="Resume Payments"
    centered
    no-close-on-backdrop
    ok-title="Ok"
    ok-only
    :ok-disabled="isLoading"
    size="sm"
    class="p-0"
    @ok="handleForm"
    @hidden="resetModal"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <validation-observer
        ref="resumePayments"
      >
        <b-row>
          <b-col
            sm="6"
          >
            <b-card
              class="mb-1"
            >
              <b-form-radio
                v-model="resumeType"
                name="resume-type"
                value="resume"
              >
                Resume
              </b-form-radio>
            </b-card>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal, BOverlay, BCard, BRow, BCol, BFormRadio,
} from 'bootstrap-vue'
import moment from 'moment'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import useHoldPaymentModal from '@/views/components/hold-payments-modal/useHoldPaymentModal'

export default {
  components: {
    ValidationObserver,

    BModal,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormRadio,
  },
  props: {
    resumePaymentOptionItem: {
      type: Object,
      default: () => {},
    },
    resumePayConditionTransaction: {
      type: Object,
      default: () => {},
    },
    applicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      resumeType: 'resume',
      resumeUntil: null,
      required,
    }
  },
  setup() {
    const {
      updateApplicationPaymentOption,
      updateApplicationPayConditionTransaction,
    } = useHoldPaymentModal()

    return {
      updateApplicationPaymentOption,
      updateApplicationPayConditionTransaction,
    }
  },
  methods: {
    resetModal() {
      this.resumeType = 'resume'
      this.resumeUntil = null
    },
    async handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      await this.$refs.resumePayments.validate()
        .then(success => {
          if (success) {
            if (this.resumePaymentOptionItem?.id) {
              this.resumeApplicationPaymentOption()
            }
            if (this.resumePayConditionTransaction?.id) {
              this.resumeApplicationPayConditionTransaction()
            }
          }
        })
    },
    async resumeApplicationPaymentOption() {
      const queryParams = {
        application_id: this.applicationId,
        ...this.resumePaymentOptionItem,
      }
      if (this.resumeType === 'resume_until') {
        queryParams.start_date = this.resumeUntil
      } else {
        queryParams.is_active = 1
        queryParams.start_date = moment().format('YYYY-MM-DD')
      }
      this.isLoading = true
      this.updateApplicationPaymentOption(queryParams).then(() => {
        this.$emit('updateData')
        this.$bvModal.hide('resume-payments')
      }).finally(() => {
        this.isLoading = false
      })
    },
    async resumeApplicationPayConditionTransaction() {
      const queryParams = {
        application_id: this.applicationId,
        id: this.resumePayConditionTransaction.id,
        status: 'Scheduled',
        is_active: 1,
        pay_date: moment().format('YYYY-MM-DD'),
      }

      this.isLoading = true
      this.updateApplicationPayConditionTransaction(queryParams).then(() => {
        this.$emit('updateData')
        this.$bvModal.hide('resume-payments')
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
