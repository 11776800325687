import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useParentProfile() {
  // Use toast
  const toast = useToast()

  const updateParent = queryParams => axios
    .put(`/auth/parents/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Parent was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error updating parent',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error.response
    })

  const fetchParent = ID => axios
    .get(`/auth/parents/${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching parent',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const deleteUser = ID => axios
    .delete(`auth/users/${ID}`)
    .then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting user',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting user',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    updateParent,
    fetchParent,
    deleteUser,
  }
}
