<template>
  <b-modal
    id="edit-notes-modal"
    ref="edit-notes-modal"
    title="Edit Internal Notes"
    centered
    ok-only
    ok-title="Save"
    @ok="save"
  >
    <!-- Textarea -->
    <b-form-group
      label="Internal Notes"
    >
      <b-form-textarea
        v-model="item.notes"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import {
  BModal, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'

import useTransactions from '@/views/admin/transactions-v2/useTransactions'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const {
      updateTransactionNotes,
      updateChargeNotes,
    } = useTransactions()

    return {
      updateTransactionNotes,
      updateChargeNotes,
    }
  },
  methods: {
    async save() {
      if (this.item.model_type === 'Charge' && this.item.type !== 'Refund' && this.item.charge_id) {
        await this.updChargeNotes().then(() => {
          this.$emit('refetchData')
        })
      } else {
        await this.updTransactionNotes().then(() => {
          this.$emit('refetchData')
        })
      }
    },
    async updTransactionNotes() {
      const queryParams = {
        notes: this.item.notes,
      }
      await this.updateTransactionNotes(this.item.id, queryParams)
    },
    async updChargeNotes() {
      const queryParams = {
        notes: this.item.notes,
      }
      await this.updateChargeNotes(this.item.charge_id, queryParams)
    },
  },
}
</script>

<style>

</style>
