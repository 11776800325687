<template>
  <ValidationObserver
    ref="adding-charge"
  >
    <b-modal
      id="adding-charge-modal"
      ref="adding-charge-modal"
      centered
      title="Adding Charge"
      ok-title="Save"
      ok-only
      no-close-on-backdrop
      @hidden="closeModal"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-row>
          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Charge List"
            >
              <v-select
                v-model="selectedFee"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="programFeesListOptions"
                label="text"
                :clearable="false"
                :reduce="val => val.value"
                :placeholder="'Select Fee'"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="isNewFeeCreating || feeId"
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Fee Name"
            >
              <validation-provider
                #default="{ errors }"
                name="fee name"
                rules="required"
              >
                <b-form-input
                  v-model="feeName"
                  type="text"
                  :disabled="!!feeId"
                  :placeholder="'Name'"
                  :class="{'is-invalid': errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="isNewFeeCreating || feeId"
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Fee Cost"
            >
              <validation-provider
                #default="{ errors }"
                name="fee cost"
                rules="required"
              >
                <b-form-input
                  v-model="feeCost"
                  type="number"
                  :disabled="!!feeId"
                  :placeholder="'$ Cost'"
                  :class="{'is-invalid': errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="isNewFeeCreating"
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Date"
            >
              <validation-provider
                #default="{ errors }"
                name="Date"
                rules="required"
              >
                <flat-pickr
                  v-model="feeDate"
                  class="form-control"
                  :placeholder="'Date'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="isNewFeeCreating"
            sm="12"
            class="mb-1"
          >
            <span>Choose the options for creating</span>
          </b-col>
          <b-col
            v-if="isNewFeeCreating"
            sm="12"
            class="mb-1"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="addToTheProgram"
                name="checkbox"
              >
                Add to the program fee list
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            v-if="validationErrors"
            sm="12"
            class="mb-1"
          >
            <p class="text-center text-danger">
              {{ validationErrors }}
            </p>
          </b-col>
        </b-row>
      </b-overlay>

      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          :disabled="isLoading"
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isLoading || !feeCost || !feeName"
          @click="handleForm"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BOverlay,
  BButton,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useAddingChargeModal from '@/views/components/adding-charge-modal/useAddingChargeModal'
import { permissionSubjects } from '@/libs/acl/constants'
import moment from '@/libs/moment'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BOverlay,
    BButton,
    BFormInput,
    BFormCheckbox,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    programId: {
      type: String,
      required: true,
    },
    applicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedFee: {},
      feeId: null,
      feeCost: null,
      feeName: null,
      feeDate: null,
      isNewFeeCreating: null,
      programFeesListOptions: [],
      addToTheProgram: false,
      isLoading: false,
      validationErrors: '',
      required,
      permissionSubjects,
    }
  },
  watch: {
    selectedFee(val) {
      if (val != null) {
        this.isNewFeeCreating = false
        this.feeId = val.id
        this.feeCost = val.cost
        this.feeName = val.name
      } else {
        this.isNewFeeCreating = true
        this.feeId = null
        this.feeCost = null
        this.feeName = null
        this.feeDate = null
      }
    },
  },
  setup() {
    const {
      fetchFees,
      applyFee,
    } = useAddingChargeModal()

    return {
      fetchFees,
      applyFee,
    }
  },
  async created() {
    await this.prepareFeesList()
  },
  methods: {
    closeModal() {
      this.selectedFee = {}
      this.feeId = null
      this.feeCost = null
      this.feeName = null
      this.feeDate = null
      this.isNewFeeCreating = false
      this.addToTheProgram = false
      this.validationErrors = ''
      this.$refs['adding-charge-modal'].hide()
    },
    async prepareFeesList() {
      const queryParam = {
        programId: this.programId,
      }
      this.programFeesListOptions = await this.fetchFees(queryParam)
        .then(response => response.reduce((arr, item) => {
          arr.push({ value: item, text: item.name })
          return arr
        }, []))
      if (this.$can('create', permissionSubjects.CustomCharge)) {
        this.programFeesListOptions.push({ value: null, text: 'Create a Custom One' })
      }
    },
    handleForm() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs['adding-charge'].validate().then(success => {
          if (success) {
            resolve(true)
            this.createCharge()
          } else {
            reject()
          }
        })
      })
    },
    async createCharge() {
      const queryParams = {
        application_id: this.applicationId,
        fee_cost: this.feeCost,
        fee_name: this.feeName,
        fee_date: this.prepareDateToUtc(this.feeDate),
        add_to_program: this.addToTheProgram,
        program_id: this.programId,
      }
      if (this.feeId) {
        queryParams.fee_id = this.feeId
      }
      this.isLoading = true
      await this.applyFee(queryParams)
        .then(() => {
          this.$emit('success')
          this.closeModal()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    prepareDateToUtc(date) {
      return moment.utc(moment(date).format('YYYY-MM-DDTHH:mmZ')).format('YYYY-MM-DD HH:mm')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
