<template>
  <ValidationObserver
    ref="edit-refund"
  >
    <b-modal
      id="edit-refund-charge-modal"
      ref="edit-refund-charge-modal"
      centered
      title="Edit Charge"
      no-close-on-backdrop
      @hidden="closeModal"
      @show="setModalData"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-row>
          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Original Payment Method"
            >
              <validation-provider
                #default="{ errors }"
                name="Original Payment Method"
                rules="required"
              >
                <v-select
                  v-model="originalPaymentMethod"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="paymentMethodsList"
                  :clearable="false"
                  label="name"
                  :reduce="option => option.name"
                  :placeholder="'Select Payment Method'"
                  :disabled="!isManual"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Credit Payment Method"
            >
              <validation-provider
                #default="{ errors }"
                name="Credit Payment Method"
                rules="required"
              >
                <v-select
                  v-model="creditPaymentMethod"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="paymentMethodsList"
                  :clearable="false"
                  label="name"
                  :reduce="option => option.name"
                  :placeholder="'Select Payment Method'"
                  :disabled="!isManual"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="creditPaymentMethod == 'Check'"
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Check Number"
            >
              <validation-provider
                #default="{ errors }"
                name="check number"
                rules="required"
              >
                <b-form-input
                  v-model="checkNumber"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Refund Amount"
            >
              <validation-provider
                #default="{ errors }"
                name="refund amount"
                :rules="{
                  required: true,
                  min_value:1,
                }"
              >
                <b-form-input
                  v-model="amount"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Date"
            >
              <validation-provider
                #default="{ errors }"
                name="Refund Date"
                rules="required"
              >
                <flat-pickr
                  v-model="date"
                  class="form-control"
                  :placeholder="'Date'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
          >
            <b-form-group
              label="Notes"
            >
              <b-form-textarea
                v-model="notes"
                placeholder="Add Refund Notes"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>

      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          :disabled="isLoading"
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isLoading"
          @click="handleForm"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BOverlay,
  BButton,
  BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min_value } from '@validations'
import useEditRefundModal from '@/views/components/edit-refund-modal/useEditRefundModal'
import moment from '@/libs/moment'
import usePaymentPopup from '@/views/components/paymentPopup/usePaymentPopup'

export default {
  components: {
    vSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BOverlay,
    BButton,
    BFormInput,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    refundId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      amount: 0,
      paymentMethodsList: [],
      isManual: false,
      originalPaymentMethod: null,
      creditPaymentMethod: null,
      checkNumber: null,
      date: null,
      notes: null,
      isLoading: false,
      timezone: localStorage.getItem('timezone'),
      required,
      min_value,
    }
  },
  setup() {
    const {
      fetchRefund,
      editRefund,
    } = useEditRefundModal()

    const {
      fetchPaymentMethods,
    } = usePaymentPopup()

    return {
      fetchRefund,
      editRefund,
      fetchPaymentMethods,
    }
  },
  async created() {
    await this.setPaymentMethodsList()
  },
  methods: {
    async setPaymentMethodsList() {
      try {
        this.isLoading = true
        this.paymentMethodsList = await this.fetchPaymentMethods()
        // move 'credit card' method to the end of the paymentMethodsList
        this.paymentMethodsList.push(this.paymentMethodsList.shift())
      } finally {
        this.isLoading = false
      }
    },
    async setModalData() {
      try {
        this.isLoading = true
        const response = await this.fetchRefund(this.refundId)
        this.originalPaymentMethod = response.original_payment_method
        this.creditPaymentMethod = response.credit_payment_method
        this.checkNumber = response.check_number
        this.amount = response.amount
        this.notes = response.notes
        this.isManual = response.is_manual
        this.date = moment(response.date).tz(this.timezone).format('YYYY-MM-DD HH:mm')
      } catch (error) {
        this.closeModal()
      } finally {
        this.isLoading = false
      }
    },
    closeModal() {
      this.checkNumber = null
      this.amount = null
      this.name = null
      this.date = null
      this.isLoading = false
      this.$refs['edit-refund-charge-modal'].hide()
    },
    handleForm() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs['edit-refund'].validate().then(success => {
          if (success) {
            resolve(true)
            this.updateRefund()
          } else {
            reject()
          }
        })
      })
    },
    async updateRefund() {
      const queryParams = {
        id: this.refundId,
        amount: this.amount,
        notes: this.notes,
        originalPaymentMethod: this.originalPaymentMethod,
        creditPaymentMethod: this.creditPaymentMethod,
        refund_date: this.prepareDateToUtc(this.date),
      }
      if (this.creditPaymentMethod === 'Check') {
        queryParams.check_number = this.checkNumber
      }
      this.isLoading = true
      await this.editRefund(this.refundId, queryParams)
        .then(() => {
          this.$emit('success')
          this.closeModal()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    prepareDateToUtc(date) {
      return moment.utc(moment(date).format('YYYY-MM-DDTHH:mmZ')).format('YYYY-MM-DD HH:mm')
    },
  },
}
</script>
