<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Profile Summary
      </h5>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">Total Payments: </span>
        </li>
        <li class="my-25">
          <span class="align-middle">Current Balance:</span>
        </li>
        <li>
          <span class="align-middle">Children Count: {{ userData.children.length }}</span>
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>
