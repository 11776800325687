import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useEditChargeModal() {
  // Use toast
  const toast = useToast()

  const fetchCharge = id => axios
    .get(`/auth/charges/${id}`)
    .then(response => response.data.data)
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Charge',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      throw new Error(error)
    })

  const editCharge = (ID, queryParams) => axios
    .put(`/auth/charges/${ID}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Charge was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Charge',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchCharge,
    editCharge,
  }
}
