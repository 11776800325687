<template>
  <ValidationObserver
    ref="combineFamiliesRules"
  >
    <b-modal
      id="combine-families-modal"
      ref="combine-families-modal"
      cancel-variant="outline-secondary"
      centered
      :title="'Add To Family'"
      ok-title="Save"
      no-close-on-backdrop
      @show="prepareData"
      @hidden="closeModal"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-row>
          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              :label="'Available Users'"
            >
              <validation-provider
                #default="{ errors }"
                :name="'User'"
                rules="required"
              >
                <v-select
                  v-model="chosenFamily"
                  :options="familiesList"
                  :filterable="false"
                  label="text"
                  value="value"
                  :placeholder="selectFieldStatus"
                  :class="{'is-invalid': errors.length > 0 }"
                  @search="onSearch"
                >
                  <li
                    v-if="metaData.current_page"
                    slot="list-footer"
                    class="pagination d-flex  align-items-center justify-content-between my-1 mx-2"
                  >
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-primary"
                      :disabled="!hasPrevPage"
                      @click="currentPage--"
                    >
                      Prev
                    </b-button>
                    <span>{{ currentPage }} of {{ metaData.last_page }}</span>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-primary"
                      :disabled="!hasNextPage"
                      @click="currentPage++"
                    >
                      Next
                    </b-button>
                  </li>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>

                <div
                  v-if="chosenFamily && chosenFamily.members.length > 1"
                  class="price-details my-1"
                >
                  <h6 class="price-title">
                    Additional Family Members:
                  </h6>
                  <ul
                    class="list-unstyled my-1"
                  >
                    <li
                      v-for="(member, index) in additionalFamilyMembersList"
                      :key="index"
                    >
                      <span class="align-middle">{{ member.name }}</span>
                    </li>
                  </ul>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>

      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          :disabled="isLoading"
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isLoading"
          @click="handleForm"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import useParentsChildrenList from '@/views/components/parents-children-list/useParentsChildrenList'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    familyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectFieldStatus: 'Loading...',
      chosenFamily: null,
      currentPage: 1,
      searchQuery: null,
      familiesList: [],
      metaData: {},
      validationErrors: '',
      isLoading: true,
      required,
    }
  },
  computed: {
    ...mapGetters({
      programId: 'verticalMenu/getDefaultProgram',
    }),
    hasNextPage() {
      return this.metaData.current_page !== this.metaData.last_page
    },
    hasPrevPage() {
      return this.currentPage > 1
    },
    additionalFamilyMembersList() {
      return this.chosenFamily.members.filter(item => item.id !== this.chosenFamily.id)
    },
  },
  watch: {
    currentPage() {
      this.fetchingFamiliesForAdd()
    },
    searchQuery() {
      this.fetchingFamiliesForAdd()
    },
  },
  setup() {
    const {
      fetchFamilyMembers,
      addStudentToFamily,
    } = useParentsChildrenList()

    return {
      fetchFamilyMembers,
      addStudentToFamily,
    }
  },
  methods: {
    async prepareData() {
      this.chosenFamily = null
      this.currentPage = 1
      this.searchQuery = null
      this.metaData = {}
      this.familiesList = []
      await this.fetchingFamiliesForAdd()
      this.isLoading = false
    },
    async fetchingFamiliesForAdd() {
      const payloadData = {
        programId: this.programId,
        perPage: 25,
        page: this.currentPage,
        sortBy: 'id',
        sortDesc: false,
        exceptFamilyId: this.familyId,
      }
      if (this.searchQuery) payloadData.name = this.searchQuery
      await this.fetchFamilyMembers(payloadData)
        .then(response => {
          this.familiesList = response.data.data.map(item => ({
            value: item.family_id,
            text: item.name,
            id: item.user_id,
            members: item.members,
          }))
          this.metaData = response.data.meta
          this.selectFieldStatus = 'Select Family'
        })
    },
    async closeModal() {
      this.$refs['combine-families-modal'].hide()
      this.$emit('success')
    },
    async handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      // eslint-disable-next-line consistent-return
      return new Promise(() => {
        this.$refs.combineFamiliesRules.validate().then(success => {
          if (success) {
            this.sendRequest()
          }
        })
      })
    },
    async onSearch(query) {
      this.searchQuery = query
      this.currentPage = 1
    },
    async sendRequest() {
      // const queryParams = {
      //   family_id: this.chosenFamily.value,
      //   user_id: this.userId,
      // }
      const queryParams = {
        family_id: this.familyId,
        user_id: this.chosenFamily.id,
      }
      this.isLoading = true
      await this.addStudentToFamily(queryParams)
        .then(() => {
          this.$emit('success')
          this.closeModal()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
