import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useAddingRefundModal() {
  // Use toast
  const toast = useToast()

  const postRefund = queryParams => axios
    .post('/auth/refunds', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Refund was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error creating Refund',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error
    })
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    postRefund,
  }
}
