<template>
  <b-row>
    <b-col
      md="8"
    >
      <!-- Payment details table -->
      <b-table
        :items="paymentDetails"
        :fields="paymentTableColumns"
        primary-key="id"
        show-empty
        empty-text="No charges"
        class="position-relative"
      >
        <!-- Column: Name -->
        <template #cell(name)="data">
          {{ data.item.description }}
        </template>

        <!-- Column: Paid -->
        <template #cell(paid_sum)="data">
          <b>{{ data.item.paid_sum | fixAmount }}</b>
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.value)}`"
          >
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canUpdateCharge"
              @click="showEditChargeModal(data.item.charge_id)"
            >
              <span>Edit Charge</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.status.toLowerCase() === 'completed' && canCancelCharge"
              @click="showCancelChargeModal(data.item.charge_id)"
            >
              <span>Cancel Charge</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.status.toLowerCase() === 'manual cancel'"
              @click="undoChargeCancel(data.item.charge_id)"
            >
              <span>Undo</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="checkIsChargeDeletable(data.item)"
              @click="openDeleteChargeConfirmation(data.item)"
            >
              <span>Delete Charge</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <!-- Footer: Total -->
        <template
          v-if="paymentDetails.length > 0"
          #bottom-row
        >
          <td class="bg-light">
            Total
          </td>
          <td class="bg-light">
            <b>{{ totalPaidCharges(paymentDetails) | fixAmount }}</b>
          </td>
          <td class="bg-light" />
          <td class="bg-light" />
        </template>
      </b-table>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BTable, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { filters } from '@core/mixins/filters'
import { transactionsMixin } from '@/views/admin/transactions-v2/mixins/transactionsMixin'

export default {
  name: 'PaymentDetailsTable',
  components: {
    BRow,
    BCol,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  mixins: [filters, transactionsMixin],
  props: {
    paymentDetails: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      paymentTableColumns: [
        { key: 'name', label: 'Charge Name' },
        { key: 'paid_sum', label: 'Amount' },
        { key: 'status', label: 'Status' },
        { key: 'actions' },
      ],
    }
  },
  methods: {
    totalPaidCharges(charges) {
      return charges.reduce((acc, curItem) => {
        if (['Manual Cancel', 'Canceled'].includes(curItem.status)) {
          return acc
        }
        return acc + Number(curItem.paid_sum)
      }, 0)
    },
  },
}
</script>

<style>

</style>
