<template>
  <b-modal
    id="adding-refund-modal"
    ref="adding-refund-modal"
    title="Add Refund"
    centered
    ok-title="Add Refund"
    ok-only
    size="sm"
    @hidden="resetModal"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <ValidationObserver
        ref="refundForm"
      >
        <b-card-text class="mb-2 text-left">
          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Original Payment Method"
            >
              <validation-provider
                #default="{ errors }"
                name="Original Payment Method"
                rules="required"
              >
                <v-select
                  v-model="originalPaymentMethod"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="paymentMethodsList"
                  :clearable="false"
                  label="name"
                  :reduce="option => option.name"
                  :placeholder="'Select Payment Method'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Credit Payment Method"
            >
              <validation-provider
                #default="{ errors }"
                name="Credit Payment Method"
                rules="required"
              >
                <v-select
                  v-model="creditPaymentMethod"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="paymentMethodsList"
                  :clearable="false"
                  label="name"
                  :reduce="option => option.name"
                  :placeholder="'Select Payment Method'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="creditPaymentMethod == 'Check'"
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Check Number"
            >
              <validation-provider
                #default="{ errors }"
                name="check number"
                rules="required"
              >
                <b-form-input
                  v-model="checkNumber"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Refund Amount"
            >
              <validation-provider
                #default="{ errors }"
                name="refund amount"
                :rules="{
                  required: true,
                  min_value:1,
                }"
              >
                <b-form-input
                  v-model="amount"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
          >
            <validation-provider
              #default="{ errors }"
              name="Refund Date"
              rules="required"
            >
              <b-form-group
                label="Refund Date"
              >
                <flat-pickr
                  v-model="refundDate"
                  class="form-control"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col
            sm="12"
          >
            <b-form-group
              label="Notes"
            >
              <b-form-textarea
                v-model="notes"
                placeholder="Add Refund Notes"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-card-text>
      </ValidationObserver>
    </b-overlay>
    <template #modal-footer>
      <b-button
        variant="primary"
        class="float-right"
        @click="handleForm"
      >
        Add Refund
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min_value } from '@validations'
import {
  BOverlay,
  BCardText,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from '@/libs/moment'
import useAddingRefundModal from '@/views/components/adding-refund-modal/useAddingRefundModal'
import usePaymentPopup from '@/views/components/paymentPopup/usePaymentPopup'

export default {
  name: 'AddingRefundModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BCardText,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    vSelect,

    flatPickr,
  },
  props: {
    applicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      paymentMethodsList: [],
      originalPaymentMethod: null,
      creditPaymentMethod: null,
      checkNumber: '',
      amount: 0,
      refundDate: new Date(),
      notes: '',
      required,
      min_value,
    }
  },
  setup() {
    const {
      postRefund,
    } = useAddingRefundModal()

    const {
      fetchPaymentMethods,
    } = usePaymentPopup()

    return {
      postRefund,
      fetchPaymentMethods,
    }
  },
  async created() {
    await this.setPaymentMethodsList()
  },
  methods: {
    async setPaymentMethodsList() {
      try {
        this.isLoading = true
        this.paymentMethodsList = await this.fetchPaymentMethods()
        // move 'credit card' method to the end of the paymentMethodsList
        this.paymentMethodsList.push(this.paymentMethodsList.shift())
      } finally {
        this.isLoading = false
      }
    },
    async handleForm() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs.refundForm.validate().then(success => {
          if (success) {
            resolve(true)
            this.sendData()
          } else {
            reject()
          }
        })
      })
    },
    async sendData() {
      try {
        const queryParams = {
          applicationId: this.applicationId,
          originalPaymentMethod: this.originalPaymentMethod,
          creditPaymentMethod: this.creditPaymentMethod,
          amount: this.amount,
          refund_date: this.prepareDateToUtc(this.refundDate),
          notes: this.notes,
        }
        if (this.creditPaymentMethod === 'Check') {
          queryParams.check_number = this.checkNumber
        }
        this.isLoading = true
        await this.postRefund(queryParams)
        this.$emit('success')
        this.closeModal()
      } finally {
        this.isLoading = false
      }
    },
    closeModal() {
      this.$refs['adding-refund-modal'].hide()
    },
    resetModal() {
      this.originalPaymentMethod = null
      this.creditPaymentMethod = null
      this.checkNumber = ''
      this.amount = 0
      this.refundDate = new Date()
      this.notes = ''
    },
    prepareDateToUtc(date) {
      return moment.utc(moment(date).format('YYYY-MM-DDTHH:mmZ'))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
