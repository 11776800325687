<template>
  <ValidationObserver
    ref="form-rules"
  >
    <b-modal
      id="bulk-change-transactions-status"
      ref="bulk-change-transactions-status"
      centered
      :title="'Edit Status For Transactions (' + markedElements.length + ' selected)'"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @hidden="closeModal"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-row>
          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Status"
            >
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <v-select
                  id="edit-status"
                  v-model="newStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusesList"
                  :clearable="false"
                  label="name"
                  placeholder="Select status"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Date"
            >
              <validation-provider
                #default="{ errors }"
                name="Date"
                rules="required"
              >
                <flat-pickr
                  v-model="newDate"
                  class="form-control"
                  placeholder="Date"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>

      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          :disabled="isLoading"
          @click="$refs['bulk-change-transactions-status'].hide()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="isLoading"
          @click="handleForm"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BModal, BOverlay, BFormGroup, BRow, BCol, BButton, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from '@/libs/moment'
import useBulkChangeTransactionsStatusModal from '@/views/components/bulk-change-transactions-status/useBulkChangeTransactionsStatusModal'

export default {
  name: 'BulkChangeTransactionsStatusModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BOverlay,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    markedElements: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      newStatus: '',
      newDate: new Date(),
      statusesList: ['Scheduled', 'Pending', 'Paid', 'Payment failed'],
      required,
    }
  },
  setup() {
    const {
      updateTransactionsStatus,
    } = useBulkChangeTransactionsStatusModal()

    return {
      updateTransactionsStatus,
    }
  },
  methods: {
    closeModal() {
      this.isLoading = false
      this.newStatus = ''
      this.newDate = new Date()
      this.$refs['bulk-change-transactions-status'].hide()
    },
    handleForm() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs['form-rules'].validate().then(success => {
          if (success) {
            resolve(true)
            this.updateStatus()
          } else {
            reject()
          }
        })
      })
    },
    async updateStatus() {
      const queryParams = {
        transactions_ids: this.markedElements,
        status: this.newStatus,
        date: this.prepareDateToUtc(this.newDate),
      }

      this.isLoading = true
      await this.updateTransactionsStatus(queryParams)
        .then(() => {
          this.$emit('success')
          this.closeModal()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    prepareDateToUtc(date) {
      return moment.utc(moment(date).format('YYYY-MM-DDTHH:mmZ')).format('YYYY-MM-DD HH:mm')
    },
  },
}
</script>
