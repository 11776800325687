<template>
  <b-modal
    :id="popupType+'-payment'"
    :ref="popupType+'-payment'"
    centered
    :title="title"
    ok-only
    size="lg"
    @ok="handleForm"
    @show="setModalData"
    @hidden="resetModal"
  >
    <validation-observer
      ref="userRules"
      tag="form"
    >
      <Spinner
        v-if="isLoading"
      />
      <b-row
        v-else
      >
        <b-col
          v-if="isApplicationSelectable"
          cols="12"
        >
          <b-form-group
            label="Application"
          >
            <validation-provider
              #default="{ errors }"
              name="Application"
              :rules="{required: isApplicationSelectable}"
            >
              <v-select
                v-model="selectedApplication"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="applications"
                placeholder="Please Select Application"
                :clearable="false"
                @input="selectApp"
              >
                <template
                  #selected-option="{ program, user }"
                >
                  <span>{{ program }}</span>&nbsp;-&nbsp;<span>{{ user.full_name }}</span>
                </template>

                <template
                  #option="{ program, user }"
                >
                  <span>{{ program }}</span> - <span>{{ user.full_name }}</span>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          sm="6"
          class="mb-1"
        >
          <b-form-group
            label="Payment Method"
          >
            <v-select
              ref="paymentMethod"
              v-model="payment.method"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="paymentMethodsList"
              :clearable="false"
              label="name"
              :reduce="option => option.name"
              :placeholder="'Select Payment Method'"
              @close="createNewPaymentMethodMode = false"
            >
              <template #option="{ name, id }">
                <div class="d-flex align-items-center justify-content-between cursor-default">
                  <span>{{ name }}</span>
                  <div
                    v-if="name !== 'Credit Card'"
                    @mousedown.stop.prevent="removePaymentMethod(id)"
                  >
                    <feather-icon
                      class="cursor-pointer"
                      icon="XIcon"
                      size="15"
                    />
                  </div>
                </div>
              </template>
              <template #list-footer>
                <li
                  v-if="!createNewPaymentMethodMode"
                  class="mx-1 py-1 border-top border-top-secondary"
                >
                  <b-button
                    class="w-100"
                    variant="outline-primary"
                    @click="createNewPaymentMethodMode = true"
                  >
                    <span class="ml-25 align-middle">
                      Add New
                      <feather-icon
                        icon="PlusIcon"
                        size="18"
                        class="ml-1"
                      />
                    </span>
                  </b-button>
                </li>
                <li
                  v-else
                  class="mx-1 py-1 border-top border-top-secondary"
                >
                  <b-form-group
                    label="Name"
                  >
                    <b-form-input
                      ref="footerInput"
                      v-model="newPaymentTypeName"
                      type="text"
                      placeholder="New type"
                      @click="handleInputFocus('paymentMethod')"
                      @keyup.enter="addNewPaymentMethod"
                    />
                  </b-form-group>
                  <div
                    class="w-100 text-primary text-center font-weight-bold cursor-pointer"
                    @click="addNewPaymentMethod"
                  >
                    <span class="ml-25 align-middle">
                      Apply
                      <feather-icon
                        icon="CheckIcon"
                        size="18"
                        class="ml-1"
                      />
                    </span>
                  </div>
                </li>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          sm="6"
          class="mb-1"
        >
          <b-form-group
            label="Amount"
          >
            <validation-provider
              #default="{ errors }"
              name="amount"
              rules="required|positive"
            >
              <b-form-input
                v-model="payment.amount"
                type="number"
                :disabled="popupType == 'edit' || (popupType == 'edit-pct' && paymentType == 'pp')"
                :state="errors.length > 0 ? false:null"
                @input="isPaymentAmountChangedManualy = true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          v-if="payment.method !== 'Check'"
          sm="6"
          class="mb-1"
        >
          <b-form-group
            :label="sessionSemester(1)"
          >
            <v-select
              v-model="payment.checkNumber"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sessionOptions"
              :placeholder="`Select ${sessionSemester(1)}`"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="payment.method == 'Credit Card'"
          sm="12"
          class="mb-1"
        >
          <b-form-group
            label="Credit Card"
          >
            <validation-provider
              #default="{ errors }"
              name="Credit Card"
              rules="required"
              :custom-messages="{required: 'No Credit Card data'}"
            >
              <b-card
                v-for="(card,index) in cardsList"
                :key="index"
                no-body
                class="d-flex flex-row align-items-center justify-content-between p-2 mb-1"
              >
                <b-form-radio
                  v-model="selectedCard"
                  name="some-radios"
                  :value="card.id"
                >
                  {{ card.type }} xxxx {{ card.last_digits }}
                </b-form-radio>
                <SquareIcon
                  v-if="cardsList.length > 1"
                  size="21"
                  icon="Trash2Icon"
                  class="cursor-pointer"
                  @onIconClick="removeCreditCard(card,index)"
                />
              </b-card>
              <b-button
                v-if="!showNewCard"
                variant="outline-primary"
                class="w-100"
                @click="showNewCard = true"
              >
                + Add New Credit Card
              </b-button>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          v-if="payment.method == 'Check'"
          sm="6"
          class="mb-1"
        >
          <b-form-group
            label="Check Number"
          >
            <validation-provider
              #default="{ errors }"
              name="check number"
              rules="required"
            >
              <b-form-input
                v-model="payment.checkNumber"
                type="text"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          v-if="payment.method == 'Check'"
          sm="6"
          class="mb-1"
        >
          <b-form-group
            label="Check Date"
          >
            <validation-provider
              #default="{ errors }"
              name="check date"
              rules="required"
            >
              <flat-pickr
                v-model="payment.checkDate"
                class="form-control"
                :config="checkDatePickerConfig"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          v-if="showNewCard && payment.method == 'Credit Card'"
          sm="12"
        >
          <b-form-group
            label="Add Credit Card"
          >
            <credit-card
              @cancel="showNewCard = false"
              @addNewCard="addNewCard"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="popupType == 'add' && chargesList && chargesList.length > 0"
          sm="12"
        >
          <b-form-group
            label="Charge list"
          >
            <draggable
              v-model="chargesList"
              handle=".handle"
            >
              <b-card
                v-for="charge in chargesList"
                :key="charge.id"
                no-body
                class="mb-1"
                style="padding: 5px"
              >
                <b-row>
                  <b-col
                    cols="8"
                    class="d-flex align-items-center"
                  >
                    <img
                      src="@/assets/images/icons/more-vertical-v2.svg"
                      class="cursor-move mr-1 handle"
                    >
                    <!-- @change="setPaymentAmount" -->
                    <b-form-checkbox
                      v-model="selectedCharges"
                      :value="charge"
                      @change="updateChargesPaySumFromSelectedCharges(charge.id)"
                    >
                      <span>{{ charge.description }}: {{ charge.charge_remaining_sum | fixAmount }}</span>
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="pay sum"
                      :rules="{
                        max_value: charge.charge_remaining_sum,
                        positive: true,
                      }"
                    >
                      <b-form-input
                        v-model="charge.pay_sum"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        @input="checkTotalChargesAmountError"
                        @blur="checkChargeCheckbox($event.target.value, charge.id)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card>
            </draggable>
            <small
              v-if="totalChargesAmountError"
              class="text-danger"
            >The charges amount should not be more than the payment total</small>
            <p v-if="extraMoney">
              This transaction will create an additional credit in the amount of {{ extraMoney | fixAmount }}.
            </p>
            <!-- <p v-if="extraMoney">
              Transaction amount is {{ extraMoney | fixAmount }} bigger than charges sum.
              You can add these funds to Family balance.
              Otherwise the unlinked to charges payment will be created.
            </p>
            <b-card
              v-if="extraMoney"
              no-body
              class="mb-1"
              style="padding: 5px"
            >
              <b-row>
                <b-col
                  cols="8"
                  class="d-flex align-items-center"
                >
                  <b-form-checkbox
                    v-model="addToCashBank"
                  >
                    <span>Add to Family balance (cashbank)</span>
                  </b-form-checkbox>
                </b-col>
                <b-col
                  cols="4"
                >
                  <b-form-input
                    v-model="extraMoney"
                    type="number"
                    :disabled="true"
                  />
                </b-col>
              </b-row>
            </b-card> -->
          </b-form-group>
        </b-col>

        <b-col
          sm="12"
        >
          <b-form-group label="Payment Type">
            <b-form-radio-group
              id="radio-group-2"
              v-model="paymentType"
              name="radio-sub-component"
              :disabled="popupType != 'add'"
            >
              <b-form-radio
                class="mb-1"
                value="normal"
              >
                Instant
              </b-form-radio>
              <b-form-radio
                class="mb-1"
                value="scheduled"
              >
                Scheduled
              </b-form-radio>
              <b-form-radio
                class="mb-1"
                value="pp"
              >
                Recurring
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>

        <b-col
          v-if="popupType !== 'add' && chargesListForLink && chargesListForLink.length > 0"
          sm="12"
        >
          <b-form-group
            label="Linked Charges list"
            handle=".handle"
          >
            <draggable
              v-model="chargesListForLink"
            >
              <b-card
                v-for="charge in chargesListForLink"
                :key="charge.id"
                no-body
                class="mb-1"
                style="padding: 5px"
              >
                <b-row>
                  <b-col
                    cols="8"
                    class="d-flex align-items-center"
                  >
                    <img
                      src="@/assets/images/icons/more-vertical-v2.svg"
                      class="cursor-move mr-1 handle"
                    >
                    <b-form-checkbox
                      v-model="selectedLinkedCharges"
                      :value="charge"
                      :disabled="charge.type.toLowerCase() === 'refund'"
                      @change="updateChargesPaySumFromSelectedCharges(charge.id)"
                    >
                      <!-- <span>{{ charge.description }}</span> -->
                      <span>{{ charge.description }}: {{ charge.charge_remaining_sum | fixAmount }}</span>
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="pay sum"
                      :rules="{
                        max_value: charge.charge_remaining_sum,
                        positive: true,
                      }"
                    >
                      <b-form-input
                        v-model="charge.pay_sum"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        :disabled="charge.type.toLowerCase() === 'refund'"
                        @input="checkTotalChargesAmountError"
                        @blur="checkChargeCheckbox($event.target.value, charge.id)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card>
            </draggable>
            <small
              v-if="totalChargesAmountError"
              class="text-danger"
            >The charges amount should not be more than the payment total</small>
            <p v-if="extraMoney">
              Unliked Payment: {{ extraMoney | fixAmount }}.
            </p>
            <!-- <p v-if="extraMoney">
              Transaction amount is {{ extraMoney | fixAmount }} bigger than charges sum.
              You can add these funds to Family balance.
              Otherwise the unlinked to charges payment will be created.
            </p>
            <b-card
              v-if="extraMoney"
              no-body
              class="mb-1"
              style="padding: 5px"
            >
              <b-row>
                <b-col
                  cols="8"
                  class="d-flex align-items-center"
                >
                  <b-form-checkbox
                    v-model="addToCashBank"
                  >
                    <span>Add to Family balance (cashbank)</span>
                  </b-form-checkbox>
                </b-col>
                <b-col
                  cols="4"
                >
                  <b-form-input
                    v-model="extraMoney"
                    type="number"
                    :disabled="true"
                  />
                </b-col>
              </b-row>
            </b-card> -->
          </b-form-group>
        </b-col>

        <template
          v-if="paymentType == 'pp'"
        >
          <b-col
            sm="6"
          >
            <b-form-group
              label="Amount Of Payments"
            >
              <validation-provider
                #default="{ errors }"
                name="amount"
                rules="required"
              >
                <b-form-input
                  v-model="paymentPlan.amountOfPayments"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                  :disabled="popupType != 'add'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="6"
          >
            <div>
              <b-form-group
                label="Monthly Payment"
              >
                <p
                  v-for="(item, index) in monthlyPayments"
                  :key="index"
                >{{ index + 1 }}. {{ item | fixAmount }}</p>
              </b-form-group>
            </div>
          </b-col>
          <b-col
            sm="6"
          >
            <validation-provider
              #default="{ errors }"
              name="Start Date"
              rules="required"
            >
              <b-form-group
                label="Start Date"
              >
                <flat-pickr
                  v-model="paymentPlan.startDate"
                  class="form-control"
                  :config="{
                    enable: [date => date >= Date.now() - 2 * 24 * 60 * 60 * 1000]
                  }"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </template>

        <b-col
          v-if="paymentType === 'scheduled'"
          sm="6"
        >
          <validation-provider
            #default="{ errors }"
            name="Payment Date"
            rules="required"
          >
            <b-form-group
              label="Payment Date"
            >
              <flat-pickr
                v-model="paymentPlan.startDate"
                class="form-control"
                :config="datePickerConfig"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col
          v-if="isTransactionPayment"
          sm="6"
        >
          <validation-provider
            #default="{ errors }"
            name="Payment Date"
            rules="required"
          >
            <b-form-group
              label="Payment Date"
            >
              <flat-pickr
                v-model="payDay"
                class="form-control"
                :config="datePickerConfig"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col
          v-if="isManualUpcomingPayment"
          sm="6"
          class="mb-1"
        >
          <b-form-group
            label="Status"
          >
            <b-form-select
              v-model="statusOfManualUpcomingPayment"
              :options="filteredStatusesListForManualUpcomingPayments"
              :disabled="!editableStatus || isScheduledStatusDisabled"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="isTransactionPayment && popupType !== 'add'"
          sm="6"
          class="mb-1"
        >
          <b-form-group
            label="Status"
          >
            <b-form-select
              v-model="statusOfManualUpcomingPayment"
              :options="statusesListForTransactionPayment"
              :disabled="!editableStatus"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6" />

        <b-col
          v-if="statusOfManualUpcomingPayment.toLowerCase() === 'payment failed'"
          sm="6"
        >
          <validation-provider
            #default="{ errors }"
            name="Date Failed"
            rules="required"
          >
            <b-form-group
              label="Date Failed"
            >
              <flat-pickr
                v-model="dateFailed"
                class="form-control"
                :config="dateFailedPickerConfig"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col
          v-if="isAvailableDepositedDate"
          sm="6"
        >
          <validation-provider
            #default="{ errors }"
            name="Date Deposited"
            rules="required"
          >
            <b-form-group
              label="Date Deposited"
            >
              <flat-pickr
                v-model="dateDeposited"
                class="form-control"
                :config="dateDepositedPickerConfig"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            label="Internal notes"
          >
            <b-form-textarea
              v-model="payment.internalNotes"
              no-resize
              placeholder="Your text"
              size="lg"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="popupType !== 'add'"
          sm="12"
          class="mb-1"
        >
          <div class="mb-1">
            Date Created: {{ payment.created_at | longDate }}
          </div>
        </b-col>

        <b-col
          v-if="errorMessage"
          sm="12"
          class="mb-1"
        >
          <p
            class="text-danger text-center"
          >
            {{ errorMessage }}
          </p>
        </b-col>

        <div
          v-if="validationErrors.length"
          class="form-group pt-1"
        >
          <p
            v-for="(validationError, index) in validationErrors"
            :key="index"
            class="text-center text-danger"
          >
            {{ validationError }}
          </p>
        </div>

      </b-row>
    </validation-observer>

    <template #modal-footer>
      <b-button
        variant="primary"
        :disabled="submitButtonIsLoading || payment.amount == 0 || totalChargesAmountError"
        @click="handleForm"
      >
        <b-spinner
          v-if="submitButtonIsLoading"
          small
        />
        <span class="ml-25 align-middle">{{ buttonText }}</span>
      </b-button>
    </template>

    <RemoveCardModal
      :cards-list="cardsList"
      :card-for-remove="cardForRemove"
      :new-card="newCardId"
      :is-loading="isLoading"
      @addNewCard="addNewCard"
      @replaceCreditCard="replaceAndDeleteCreditCard"
    />

  </b-modal>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormRadioGroup, BFormCheckbox, BFormInput, BFormSelect,
  VBModal, BFormTextarea, BCard, BFormRadio, BButton, BSpinner,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import axios from '@axios'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, positive, max_value, isValidChargesSum,
} from '@validations'

import { filters } from '@core/mixins/filters'
import flatPickr from 'vue-flatpickr-component'
import draggable from 'vuedraggable'
import moment from '@/libs/moment'
import CreditCard from '@/views/components/credit-card/CreditCard.vue'
import RemoveCardModal from '@/views/components/paymentPopup/RemoveCardModal.vue'
import usePaymentPopup from '@/views/components/paymentPopup/usePaymentPopup'
import Spinner from '@/views/components/table-spinner/TableSpinner.vue'
import SquareIcon from '@/views/ui/icon/SquareIcon.vue'

export default {
  name: 'PaymentPopup',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormCheckbox,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BCard,
    BFormRadio,
    BButton,
    BSpinner,

    vSelect,
    flatPickr,
    SquareIcon,

    CreditCard,
    RemoveCardModal,

    ValidationProvider,
    ValidationObserver,

    Spinner,
    draggable,
  },
  directives: {
    'b-modal': VBModal,
  },
  filters: {
    fixAmount(val) {
      return val >= 0 ? `$${val}` : `-$${Math.abs(val)}`
    },
    invertingAmount(val) {
      return val >= 0 ? Number(val).toFixed(2) : Math.abs(Number(val)).toFixed(2)
    },
  },
  mixins: [filters],
  props: {
    paymentPlanExists: {
      type: Boolean,
      default: false,
    },
    popupType: {
      type: String,
      required: true,
    },
    userId: {
      type: [Number, String],
      required: true,
    },
    applicationId: {
      type: [Number, String],
      required: false,
      default: '',
    },
    isApplicationSelectable: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: [Number, String],
      required: false,
      default: '',
    },
    cardId: {
      type: [Number, String],
      required: false,
      default: '',
    },
    originId: {
      type: [Number, String],
      required: false,
      default: '',
    },
    editablePaymentItem: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: 'Make Payment',
    },
    buttonText: {
      type: String,
      default: 'Submit Payment',
    },
  },
  data() {
    return {
      payment: {
        method: 'Credit Card',
        amount: 0,
        internalNotes: '',
        checkNumber: '',
        checkDate: new Date(),
      },
      paymentPlan: {
        amountOfPayments: 1,
        startDate: new Date(),
      },
      payDay: new Date(),
      dateFailed: new Date(),
      dateDeposited: new Date(),
      cardsList: [],
      selectedCard: null,
      cardForRemove: null,
      newCardId: '',
      showNewCard: false,
      paymentType: 'normal',
      applications: [],
      selectedApplication: null,
      selectedCharges: [],
      chargesList: [],
      chargesListForLink: [],
      selectedLinkedCharges: [],
      errorMessage: '',
      isLoading: false,
      submitButtonIsLoading: false,
      required,
      positive,
      max_value,
      isValidChargesSum,
      statusesListForManualUpcomingPayments: ['Scheduled', 'Pending', 'Paid', 'Payment failed'],
      statusesListForTransactionPayment: ['Completed', 'Payment Failed', 'Partial Refunded', 'Refunded'],
      statusOfManualUpcomingPayment: 'Scheduled',
      validationErrors: [],
      editableAppPayCondTransaction: {},
      editableTransactionId: {},
      paymentMethodsList: [],
      createNewPaymentMethodMode: false,
      newPaymentTypeName: '',
      extraMoney: 0,
      addToCashBank: false,
      totalChargesAmountError: false,
      isPaymentAmountChangedManualy: false,
      timezone: localStorage.getItem('timezone'),
      isScheduledStatusDisabled: false,
      isStartDateIsInFuture: false,
      sessionOptions: [],
    }
  },
  computed: {
    programId() {
      return this.$store.getters['verticalMenu/getDefaultProgram']
    },
    dateFailedPickerConfig() {
      return {}
    },
    dateDepositedPickerConfig() {
      return {}
    },
    checkDatePickerConfig() {
      return {}
    },
    datePickerConfig() {
      if (this.paymentType === 'scheduled' && this.popupType === 'add' && this.payment.method === 'Credit Card') {
        return {
          enable: [date => date >= Date.now() - 2 * 24 * 60 * 60 * 1000],
        }
      }

      if (this.paymentType === 'scheduled' && this.payment.method !== 'Credit Card') {
        return {
          // set condition for rebuild date picker
          enable: [date => date >= new Date().setFullYear(new Date().getFullYear() - 5)],
        }
      }

      if (this.paymentType === 'normal') {
        return {
          enable: [date => date <= Date.now() + 1 * 24 * 60 * 60 * 1000],
        }
      }

      return {}
    },
    isCCMethod() {
      return ['Credit Card'].includes(this.payment.method)
    },
    monthlyPayment() {
      return (this.payment.amount / this.paymentPlan.amountOfPayments).toFixed(2)
    },
    isManualUpcomingPayment() {
      return this.paymentType === 'scheduled' || this.paymentType === 'pp'
    },
    isTransactionPayment() {
      return this.paymentType === 'normal'
    },
    editableStatus() {
      return !this.isCCMethod
    },
    monthlyPayments() {
      const result = []
      const val = (this.payment.amount / this.paymentPlan.amountOfPayments).toFixed(0)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.paymentPlan.amountOfPayments; i++) {
        if (i !== this.paymentPlan.amountOfPayments - 1) {
          result.push(Number(val).toFixed(2))
        } else {
          result.push((Number(this.payment.amount) - Number(result.reduce((sum, elem) => sum + Number(elem), 0))).toFixed(2))
        }
      }
      return result
    },
    selectedChargesSum() {
      return this.selectedCharges.reduce((acc, curItem) => acc + Number(curItem.pay_sum), 0)
    },
    selectedLinkedChargesSum() {
      return this.selectedLinkedCharges.reduce((acc, curItem) => acc + Number(curItem.pay_sum), 0)
    },
    isAvailableDepositedDate() {
      return this.statusOfManualUpcomingPayment.toLowerCase() === 'paid' && this.payment.method !== 'Credit Card'
    },
    filteredStatusesListForManualUpcomingPayments() {
      if (this.popupType === 'add') {
        if (this.isStartDateIsInFuture) {
          return this.statusesListForManualUpcomingPayments
        }
        return this.statusesListForManualUpcomingPayments.filter(item => item !== 'Scheduled')
      }
      return this.statusesListForManualUpcomingPayments
    },
  },
  setup() {
    const {
      sendTransaction,
      postApplicationPayConditions,
      fetchCards,
      postCard,
      replaceCard,
      deleteCard,
      updatePaymentPlanTransaction,
      payForConditionTransaction,
      updateTransaction,
      fetchPaymentMethods,
      postPaymentMethod,
      deletePaymentMethod,
      fetchSessions,
    } = usePaymentPopup()

    return {
      sendTransaction,
      postApplicationPayConditions,
      fetchCards,
      postCard,
      replaceCard,
      deleteCard,
      updatePaymentPlanTransaction,
      payForConditionTransaction,
      updateTransaction,
      fetchPaymentMethods,
      postPaymentMethod,
      deletePaymentMethod,
      fetchSessions,
    }
  },
  watch: {
    selectedCharges: {
      deep: true,
      handler() {
        this.checkIfPaymentIsNotManualyAddedOnSelectingCharge()
        this.checkForExtraMoney()
        this.checkTotalChargesAmountError()
      },
    },
    selectedLinkedCharges: {
      deep: true,
      handler() {
        this.checkForExtraMoney()
        this.checkTotalChargesAmountError()
      },
    },
    'payment.amount': function fn(val) {
      if (Number(val) === 0) {
        this.isPaymentAmountChangedManualy = false
      }
      this.checkForExtraMoney()
      this.checkTotalChargesAmountError()
    },
    'paymentPlan.startDate': function fn(newVal) {
      // check if newStartDate is in the future
      if (this.popupType === 'add') {
        const newDateIsInFuture = moment(newVal).isAfter(new Date(), 'day')
        if (newDateIsInFuture) {
          this.isStartDateIsInFuture = true
          this.isScheduledStatusDisabled = true
          this.statusOfManualUpcomingPayment = 'Scheduled'
        } else {
          this.isStartDateIsInFuture = false
          this.isScheduledStatusDisabled = false
          this.statusOfManualUpcomingPayment = 'Pending'
        }
      }
    },
    paymentType(val) {
      if (val !== 'scheduled') {
        this.statusOfManualUpcomingPayment = 'Scheduled'
      }
    },
  },
  async created() {
    if (this.isApplicationSelectable) {
      this.fetchApplications()
    }

    await this.setPaymentMethodsList()
    await this.setSessionsList()
  },
  methods: {
    resetModal() {
      this.payment.method = 'Credit Card'
      this.payment.amount = null
      this.payment.internalNotes = ''
      this.payment.checkNumber = ''
      this.payment.checkDate = new Date()

      this.paymentPlan.amountOfPayments = 1
      this.paymentPlan.startDate = new Date()

      this.payDay = new Date()
      this.dateFailed = new Date()
      this.dateDeposited = new Date()

      this.selectedApplication = null
      this.selectedCard = null
      this.chargesList = []
      this.selectedCharges = []
      this.selectedLinkedCharges = []
      this.statusOfManualUpcomingPayment = 'Scheduled'

      this.editableTransactionId = {}
      this.editableAppPayCondTransaction = {}
      this.paymentType = 'normal'
      this.addToCashBank = false
      this.totalChargesAmountError = false
      this.isPaymentAmountChangedManualy = false
      this.isScheduledStatusDisabled = false
      this.isStartDateIsInFuture = false
    },
    selectAllCharges() {
      this.selectedCharges = this.chargesList.filter(charge => charge.charge_remaining_sum !== 0)
      this.setPaymentAmount()
    },
    async setCardsList() {
      const queryParams = {
        user_id: this.userId,
        application_id: this.applicationId,
      }
      this.cardsList = await this.fetchCards(queryParams)
      if (this.editablePaymentItem?.id) {
        this.addPayConditionCard()
      }
      if (this.cardId) {
        this.selectedCard = this.cardId
      }
      if (this.cardsList.length === 1 && !this.selectedCard) {
        this.selectedCard = this.cardsList[0]?.id
      }
    },
    async setPaymentMethodsList() {
      this.paymentMethodsList = await this.fetchPaymentMethods()
      // move 'credit card' method to the end of the paymentMethodsList
      this.paymentMethodsList.push(this.paymentMethodsList.shift())
      this.selectDefaultPaymentMethod()
    },
    selectDefaultPaymentMethod() {
      if (this.popupType === 'add') {
        const paymentMethodsNames = this.paymentMethodsList.map(item => item.name)
        if (paymentMethodsNames.includes('External Credit Card')) {
          this.payment.method = 'External Credit Card'
        } else {
          this.payment.method = 'Credit Card'
        }
      }
    },
    selectApp(application) {
      this.selectApplicationCard(application.card_id)
      this.updateChargesList(application.id)
    },
    selectApplicationCard(cardId) {
      this.selectedCard = cardId
    },
    async updateChargesList(applicationId) {
      this.isLoading = true
      await this.fetchCharges(applicationId).then(() => {
        // this.selectAllCharges()
        this.isLoading = false
      })
    },
    setPaymentAmount() {
      if (this.isManualUpcomingPayment && this.popupType !== 'add') {
        if (this.editableAppPayCondTransaction.application_pay_condition.count_of_payments > 1) {
          this.payment.amount = this.editablePaymentItem.amount
        } else {
          this.payment.amount = this.editableAppPayCondTransaction.amount
        }
      } else if (this.popupType === 'add') {
        // this.payment.amount = this.selectedCharges.reduce((acc, curItem) => acc + Number(curItem.charge_remaining_sum), 0)
        this.payment.amount = 0
      }

      this.payment.amount = this.$options.filters.invertingAmount(this.payment.amount)
    },
    async addNewCard(card) {
      // eslint-disable-next-line no-param-reassign
      card.user_id = this.userId
      this.isLoading = true
      await this.postCard(card).then(response => {
        if (response.id) {
          this.newCardId = response.id
          this.selectedCard = response.id
          this.setCardsList().then(() => {
            this.showNewCard = false
          })
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
    async removeCreditCard(card) {
      this.cardForRemove = card
      if (card.card_is_use) {
        this.$bvModal.show('replace-card')
      } else {
        this.isLoading = true
        await this.deleteCard(card.id)
          .then(() => {
            this.setCardsList()
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    async replaceAndDeleteCreditCard(cardId) {
      this.isLoading = true
      await this.replaceCard(this.cardForRemove.id, { card_id: cardId })
        .then(() => {
          this.deleteCard(this.cardForRemove.id)
            .then(() => {
              this.setCardsList()
            })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      await this.$refs.userRules.validate()
        .then(success => {
          if (success) {
            if (this.editableAppPayCondTransaction?.id && this.isManualUpcomingPayment) {
              this.editPaymentPlanTransaction()
            } else if (this.popupType === 'add' && this.isManualUpcomingPayment) {
              this.sendPaymentPlan()
            } else if (this.popupType === 'edit-pct') {
              this.editTransaction()
            } else if (this.popupType === 'edit') {
              this.payForPayConditionTransaction()
            } else {
              this.sendNewTransaction()
            }
          }
        })
    },
    async sendPaymentPlan() {
      this.submitButtonIsLoading = true

      const queryParams = {
        application_id: this.selectedApplication?.id || this.applicationId,
        condition_type: this.isManualUpcomingPayment ? null : 'PaymentPlan',
        payment_method: this.payment.method,
        amount: this.payment.amount,
        count_of_payments: this.paymentPlan.amountOfPayments,
        start_date: this.prepareDateToUtc(this.paymentPlan.startDate),
        is_active: this.isManualUpcomingPayment,
        is_manual_upcoming_payment: this.isManualUpcomingPayment,
        transaction_data: {
          notes: this.payment.internalNotes,
          check_number: this.payment.checkNumber,
        },
        status: this.statusOfManualUpcomingPayment,
        charges: this.prepareSelectedCharges(),
        add_to_cash_bank: this.addToCashBank,
      }

      if (this.payment.method === 'Credit Card') {
        queryParams.card_id = this.selectedCard
      }

      if (this.payment.method === 'Check') {
        queryParams.transaction_data.check_date = this.prepareDateToUtc(this.payment.checkDate)
      }

      if (this.isAvailableDepositedDate) {
        queryParams.transaction_data.date_deposited = this.prepareDateToUtc(this.dateDeposited)
      }

      if (!this.isCCMethod) {
        queryParams.transaction_data.status = this.statusOfManualUpcomingPayment
      }

      this.validationErrors = []
      await this.postApplicationPayConditions(queryParams).then(err => {
        if (err) {
          if (err.status === 422) {
            const validationErrors = Object.values(err.data.errors)
            this.validationErrors = validationErrors.flat()
          } else {
            this.errorMessage = err.data.message
          }
          this.submitButtonIsLoading = false
          return
        }
        this.$nextTick(() => {
          this.submitButtonIsLoading = false
          this.$refs[`${this.popupType}-payment`].hide()
          this.$emit('paymentAdded')
        })
      })
    },
    async sendNewTransaction() {
      this.submitButtonIsLoading = true

      const queryParams = {
        user_id: this.userId,
        application_id: this.selectedApplication?.id || this.applicationId,
        origin_id: this.originId,
        amount: this.payment.amount,
        check_number: this.payment.checkNumber,
        type: this.payment.method,
        notes: this.payment.internalNotes,
        charges: this.prepareSelectedCharges(),
        pay_date: this.prepareDateToUtc(this.payDay),
        add_to_cash_bank: this.addToCashBank,
      }

      if (this.payment.method === 'Credit Card' && this.selectedCard) {
        queryParams.card_id = this.selectedCard
      }

      if (this.payment.method === 'Check') {
        queryParams.check_date = this.prepareDateToUtc(this.payment.checkDate)
      }

      await this.sendTransaction(queryParams).then(err => {
        if (err) {
          this.errorMessage = err.data.message
          this.submitButtonIsLoading = false
          return
        }
        this.$nextTick(() => {
          this.submitButtonIsLoading = false
          this.$refs[`${this.popupType}-payment`].hide()
          this.$emit('paymentAdded')
        })
      })
    },
    async payForPayConditionTransaction() {
      this.isLoading = true

      const queryParams = {
        payment_method: this.payment.method,
        notes: this.payment.internalNotes,
      }
      if (this.payment.method === 'Credit Card' && this.selectedCard) {
        queryParams.card_id = this.selectedCard
      }
      const { id } = this.editablePaymentItem
      await this.payForConditionTransaction(id, queryParams).then(err => {
        if (err) {
          this.errorMessage = err.data.message
          this.submitButtonIsLoading = false
          this.isLoading = false
          return
        }
        this.$nextTick(() => {
          this.submitButtonIsLoading = false
          this.$refs[`${this.popupType}-payment`].hide()
          this.$emit('paymentAdded')
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    async editPaymentPlanTransaction() {
      this.submitButtonIsLoading = true
      let id = null

      const queryParams = {
        payment_method: this.payment.method,
      }

      if (this.isManualUpcomingPayment && this.editableAppPayCondTransaction) {
        queryParams.pay_date = this.prepareDateToUtc(this.paymentPlan.startDate)
        queryParams.status = this.statusOfManualUpcomingPayment
        queryParams.amount = this.payment.amount
        id = this.editableAppPayCondTransaction.id

        queryParams.transaction_data = {
          notes: this.payment.internalNotes,
          is_not_cc_payment: !this.isCCMethod,
          check_number: this.payment.checkNumber,
          charges: this.prepareSelectedLinkedCharges(),
          add_to_cash_bank: this.addToCashBank,
        }

        if (this.payment.method === 'Check') {
          queryParams.transaction_data.check_date = this.prepareDateToUtc(this.payment.checkDate)
        }
        if (this.statusOfManualUpcomingPayment.toLowerCase() === 'payment failed') {
          queryParams.transaction_data.date_failed = this.prepareDateToUtc(this.dateFailed)
        }
        if (this.isAvailableDepositedDate) {
          queryParams.transaction_data.date_deposited = this.prepareDateToUtc(this.dateDeposited)
        }
        if (!this.isCCMethod && this.statusesListForTransactionPayment.includes(this.statusOfManualUpcomingPayment)) {
          queryParams.transaction_data.status = this.statusOfManualUpcomingPayment
        }
      } else {
        queryParams.pay_date = this.prepareDateToUtc(this.payDay)
        queryParams.notes = this.payment.internalNotes

        id = this.editablePaymentItem?.id
      }

      if (this.payment.method === 'Credit Card' && this.selectedCard) {
        queryParams.card_id = this.selectedCard
      }

      this.validationErrors = []
      await this.updatePaymentPlanTransaction(id, queryParams).then(err => {
        if (err) {
          if (err.status === 422) {
            const validationErrors = Object.values(err.data.errors)
            this.validationErrors = validationErrors.flat()
          } else {
            this.errorMessage = err.data.message
          }
          this.submitButtonIsLoading = false
          return
        }
        this.$nextTick(() => {
          this.submitButtonIsLoading = false
          this.$refs[`${this.popupType}-payment`].hide()
          this.$emit('paymentAdded')
        })
      })
    },
    async editTransaction() {
      this.submitButtonIsLoading = true

      const queryParams = {
        pay_date: this.prepareDateToUtc(this.payDay),
        notes: this.payment.internalNotes,
        check_number: this.payment.checkNumber,
        amount: this.payment.amount,
        type: this.payment.method,
        is_not_cc_payment: !this.isCCMethod,
        charges: this.prepareSelectedLinkedCharges(),
        add_to_cash_bank: this.addToCashBank,
        handling_extra_money: true,
      }

      if (this.payment.method === 'Check') {
        queryParams.check_date = this.prepareDateToUtc(this.payment.checkDate)
      }

      if (this.statusOfManualUpcomingPayment.toLowerCase() === 'payment failed') {
        queryParams.date_failed = this.prepareDateToUtc(this.dateFailed)
      }

      if (this.isAvailableDepositedDate) {
        queryParams.date_deposited = this.prepareDateToUtc(this.dateDeposited)
      }

      if (!this.isCCMethod) {
        queryParams.status = this.statusOfManualUpcomingPayment
      }

      this.validationErrors = []
      await this.updateTransaction(this.editableTransactionId, queryParams).then(response => {
        if (response.status === 422) {
          const validationErrors = Object.values(response.data.errors)
          this.validationErrors = validationErrors.flat()
          this.submitButtonIsLoading = false
          return
        }
        this.$nextTick(() => {
          this.submitButtonIsLoading = false
          this.$refs[`${this.popupType}-payment`].hide()
          this.$emit('paymentAdded')
        })
      })
    },
    fetchApplications() {
      const queryParams = {
        parentId: this.userId,
      }

      axios
        .get('/auth/applications', {
          params: queryParams,
        })
        .then(response => {
          this.applications = response.data.data
        })
        .catch(() => {})
    },
    async fetchCharges(applicationId) {
      const queryParams = {
        applicationId,
        is_without_charge: 0,
        except_status: ['Canceled', 'Manual Cancel'],
      }

      await axios
        .get('/auth/transactions', {
          params: queryParams,
        })
        .then(response => {
          this.chargesList = response.data.data
            .filter(charge => charge.charge_remaining_sum > 0 && !charge.is_bounced_check)
            .map(charge => ({ ...charge, pay_sum: 0 }))

          this.setLinkedChargesList(response.data.data)
        })
        .catch(() => {})
    },
    setCharges() {
      if (this.applicationId) {
        this.updateChargesList(this.applicationId)
      }
    },
    setPaymentTransactionData() {
      if (this.editablePaymentItem?.id) {
        this.payDay = moment(this.editablePaymentItem.pay_date).tz(this.timezone).format('YYYY-MM-DD HH:mm')
        this.payment.amount = this.editablePaymentItem.amount.toFixed(2)
        if (this.editablePaymentItem.type === 'Credit Card') {
          this.selectedCard = this.editablePaymentItem.card.id
          this.addPayConditionCard()
        }
      }
    },
    setPaymentEditableData() {
      if (!this.editablePaymentItem) {
        return
      }
      this.isPaymentAmountChangedManualy = true
      this.editableTransactionId = this.editablePaymentItem?.id
      this.editableAppPayCondTransaction = this.editablePaymentItem?.app_pay_condition_transaction
      if (this.editableAppPayCondTransaction?.id) {
        this.paymentPlan.startDate = moment(this.editableAppPayCondTransaction.pay_date).tz(this.timezone).format('YYYY-MM-DD HH:mm')
        this.payment.method = this.editableAppPayCondTransaction.payment_method
        this.statusOfManualUpcomingPayment = this.editableAppPayCondTransaction.status
        this.paymentType = this.editableAppPayCondTransaction?.application_pay_condition?.is_manual_upcoming_payment ? 'scheduled' : 'pp'
        if (this.editableAppPayCondTransaction.card) {
          this.selectedCard = this.editableAppPayCondTransaction.card.id
        }
      } else {
        this.statusOfManualUpcomingPayment = this.editablePaymentItem.status
        this.payment.amount = this.editablePaymentItem.amount.toFixed(2)
        this.payment.method = this.editablePaymentItem.type
        this.payDay = moment(this.editablePaymentItem.pay_date).tz(this.timezone).format('YYYY-MM-DD HH:mm')
      }

      this.selectedLinkedCharges = this.editablePaymentItem.paidForTransactions
        .filter(charge => charge.type.toLowerCase() !== 'unlinked payment')
        .map(item => ({
          ...item,
          charge_remaining_sum: item.charge_remaining_sum + item.paid_sum,
          pay_sum: item.paid_sum,
        }))

      if (this.editablePaymentItem?.bounced_check) {
        this.dateFailed = this.editablePaymentItem.bounced_check.date_failed
      }

      this.dateDeposited = this.editablePaymentItem.date_deposited ? this.editablePaymentItem.date_deposited : new Date()
      this.payment.internalNotes = this.editablePaymentItem.notes
      this.payment.checkNumber = this.editablePaymentItem.check_number
      if (this.editablePaymentItem.check_date) {
        this.payment.checkDate = moment(this.editablePaymentItem.check_date).tz(this.timezone).format('YYYY-MM-DD HH:mm')
      }
      this.payment.created_at = this.editablePaymentItem.created_at
    },
    addPayConditionCard() {
      if (this.editablePaymentItem.card && !this.checkIfCardIsInTheList(this.editablePaymentItem.card.id)) {
        this.cardsList.push(this.editablePaymentItem.card)
      }
    },
    checkIfCardIsInTheList(id) {
      return this.cardsList.some(el => el.id === id)
    },
    setModalData() {
      this.setPaymentMethodsList()
      this.setCardsList()
      this.setPaymentEditableData()
      this.setPaymentTransactionData()
      this.setCharges()
      this.setPaymentAmount()
      this.validationErrors = []
      this.errorMessage = ''
    },
    handleInputFocus(refId) {
      // Focus footerInput manually
      this.$refs.footerInput.focus()
      // Keep the dropdown open
      this.$refs[refId].open = true
    },
    async addNewPaymentMethod() {
      // eslint-disable-next-line no-param-reassign
      if (this.newPaymentTypeName) {
        const data = {
          name: this.newPaymentTypeName,
        }
        this.isLoading = true
        await this.postPaymentMethod(data).then(response => {
          if (response.id) {
            this.paymentMethodsList.push(response)
            this.payment.method = response.name
            this.createNewPaymentMethodMode = false
            this.newPaymentTypeName = ''
          }
        }).finally(() => {
          this.isLoading = false
        })
      }
    },
    async removePaymentMethod(paymentMethodId) {
      this.isLoading = true
      await this.deletePaymentMethod(paymentMethodId)
        .then(() => {
          this.setPaymentMethodsList()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setLinkedChargesList(data) {
      const chargesListForLink = []
      if (this.selectedLinkedCharges.length) {
        chargesListForLink.push(...this.selectedLinkedCharges)
      }
      const chargesListForLinkIds = chargesListForLink.map(item => item.id)
      const responseCharges = data
        .filter(charge => charge.charge_remaining_sum > 0)
        .map(charge => ({ ...charge, pay_sum: 0 }))

      responseCharges.forEach(item => {
        if (!chargesListForLinkIds.includes(item.id)) {
          chargesListForLink.push(item)
        }
      })
      this.chargesListForLink = [...chargesListForLink].filter(
        charge => charge.status.toLowerCase() !== 'refund to cash bank' && charge.type.toLowerCase() !== 'unlinked payment',
      )
      // this.chargesListForLink.sort((a, b) => a.description.localeCompare(b.description))
      this.chargesListForLink.sort((a, b) => a.position - b.position)
    },
    prepareSelectedCharges() {
      return this.selectedCharges
        .sort((a, b) => this.chargesList.indexOf(a) - this.chargesList.indexOf(b))
        .map(charge => ({
          id: charge.charge_id,
          pay_sum: charge.pay_sum,
        }))
    },
    prepareSelectedLinkedCharges() {
      return this.selectedLinkedCharges
        .filter(charge => charge.type.toLowerCase() !== 'refund')
        .sort((a, b) => this.chargesListForLink.indexOf(a) - this.chargesListForLink.indexOf(b))
        .map(charge => ({
          id: charge.charge_id,
          pay_sum: charge.pay_sum,
        }))
    },
    updateChargesPaySumFromPaymentAmount(val) {
      let amount = Number(val)
      if (this.popupType === 'add') {
        this.selectedCharges = []
        this.chargesList.forEach(charge => {
          // eslint-disable-next-line no-param-reassign
          charge.pay_sum = 0
          if (amount >= charge.charge_remaining_sum) {
            // eslint-disable-next-line no-param-reassign
            charge.pay_sum = charge.charge_remaining_sum
            amount -= charge.charge_remaining_sum
            this.selectedCharges.push(charge)
          } else if (amount) {
            // eslint-disable-next-line no-param-reassign
            charge.pay_sum = amount
            amount = 0
            this.selectedCharges.push(charge)
          }
        })
      } else {
        this.selectedLinkedCharges = []
        this.chargesListForLink.filter(charge => charge.type.toLowerCase() === 'refund')
          .forEach(charge => {
            this.selectedLinkedCharges.push(charge)
            if (amount - charge.pay_sum > 0) {
              amount -= charge.pay_sum
            } else {
              amount = 0
            }
          })
        this.chargesListForLink.filter(charge => charge.type.toLowerCase() !== 'refund')
          .forEach(charge => {
            // eslint-disable-next-line no-param-reassign
            charge.pay_sum = 0
            if (amount >= charge.charge_remaining_sum) {
              // eslint-disable-next-line no-param-reassign
              charge.pay_sum = charge.charge_remaining_sum
              amount -= charge.charge_remaining_sum
              this.selectedLinkedCharges.push(charge)
            } else if (amount) {
              // eslint-disable-next-line no-param-reassign
              charge.pay_sum = amount
              amount = 0
              this.selectedLinkedCharges.push(charge)
            }
          })
      }
      this.checkForExtraMoney()
    },
    updateChargesPaySumFromSelectedCharges(chargeId, isChargeAmountChangedManualy = false) {
      if (this.popupType === 'add') {
        const found = this.chargesList.find(item => item.id === chargeId)
        if (!this.selectedCharges.some(item => item.id === chargeId)) {
          found.pay_sum = 0
        } else if (this.isPaymentAmountChangedManualy && !isChargeAmountChangedManualy) {
          const chargesAmountExceptCurrent = this.selectedCharges
            .filter(item => item.id !== chargeId)
            .reduce((acc, curItem) => acc + Number(curItem.pay_sum), 0)

          const difference = this.payment.amount - chargesAmountExceptCurrent
          found.pay_sum = difference < found.charge_remaining_sum ? difference : found.charge_remaining_sum
        } else {
          found.pay_sum = found.pay_sum ? found.pay_sum : found.charge_remaining_sum
        }
      } else {
        const found = this.chargesListForLink.find(item => item.id === chargeId)
        if (!this.selectedLinkedCharges.some(item => item.id === chargeId)) {
          found.pay_sum = 0
        } else if (this.isPaymentAmountChangedManualy && !isChargeAmountChangedManualy) {
          const chargesAmountExceptCurrent = this.selectedLinkedCharges
            .filter(item => item.id !== chargeId)
            .reduce((acc, curItem) => acc + Number(curItem.pay_sum), 0)

          const difference = this.payment.amount - chargesAmountExceptCurrent
          found.pay_sum = difference < found.charge_remaining_sum ? difference : found.charge_remaining_sum
        } else {
          found.pay_sum = found.pay_sum ? found.pay_sum : found.charge_remaining_sum
        }
      }
      this.checkForExtraMoney()
    },
    checkForExtraMoney() {
      this.extraMoney = 0
      const paymentAmount = this.payment.amount
      if (this.popupType === 'add') {
        const chargesAmount = this.selectedCharges.reduce((acc, curItem) => acc + Number(curItem.pay_sum), 0)
        if (paymentAmount > chargesAmount) {
          this.extraMoney = paymentAmount - chargesAmount
        }
      } else {
        const chargesAmount = this.selectedLinkedCharges.reduce((acc, curItem) => acc + Number(curItem.pay_sum), 0)
        if (paymentAmount > chargesAmount) {
          this.extraMoney = paymentAmount - chargesAmount
        }
      }
    },
    checkChargeCheckbox(val, chargeId) {
      const amount = Number(val)
      if (amount > 0) {
        if (this.popupType === 'add') {
          const isExist = this.selectedCharges.some(item => item.id === chargeId)
          if (!isExist) {
            const charge = this.chargesList.find(item => item.id === chargeId)
            this.selectedCharges.push(charge)
          }
        } else {
          const isExist = this.selectedLinkedCharges.some(item => item.id === chargeId)
          if (!isExist) {
            const charge = this.chargesListForLink.find(item => item.id === chargeId)
            this.selectedLinkedCharges.push(charge)
          }
        }

        this.updateChargesPaySumFromSelectedCharges(chargeId, true)
      } else if (amount === 0) {
        if (this.popupType === 'add') {
          this.selectedCharges = this.selectedCharges.filter(charge => charge.id !== chargeId)
        } else {
          this.selectedLinkedCharges = this.selectedLinkedCharges.filter(charge => charge.id !== chargeId)
        }
      }
    },
    checkTotalChargesAmountError() {
      if (this.selectedChargesSum > Number(this.payment.amount)
      || this.selectedLinkedChargesSum > Number(this.payment.amount)) {
        this.totalChargesAmountError = true
      } else {
        this.totalChargesAmountError = false
      }
    },
    checkIfPaymentIsNotManualyAddedOnSelectingCharge() {
      if (!this.isPaymentAmountChangedManualy) {
        this.payment.amount = this.selectedCharges.reduce((acc, curItem) => acc + Number(curItem.pay_sum), 0)
      }
    },
    prepareDateToUtc(date) {
      return moment.utc(moment(date).format('YYYY-MM-DDTHH:mmZ')).format('YYYY-MM-DD HH:mm')
    },
    async setSessionsList() {
      this.sessionOptions = await this.fetchSessions({ programId: this.programId })
        .then(response => response.reduce((arr, item) => {
          arr.push(item.name)
          return arr
        }, []))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.cursor-move span{
  cursor: move;
}
</style>
