<template>
  <ValidationObserver
    ref="edit-charge"
  >
    <b-modal
      id="edit-charge-modal"
      ref="edit-charge-modal"
      centered
      title="Edit Charge"
      no-close-on-backdrop
      @hidden="closeModal"
      @show="setModalData"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-row>
          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Charge Name"
            >
              <validation-provider
                #default="{ errors }"
                name="Charge Name"
                rules="required"
              >
                <b-form-input
                  v-model="name"
                  type="text"
                  :placeholder="'Name'"
                  :class="{'is-invalid': errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Charge Cost"
            >
              <validation-provider
                #default="{ errors }"
                name="Charge Cost"
                rules="required"
              >
                <b-form-input
                  v-model="cost"
                  type="number"
                  :placeholder="'$ Cost'"
                  :class="{'is-invalid': errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Date"
            >
              <validation-provider
                #default="{ errors }"
                name="Date"
                rules="required"
              >
                <flat-pickr
                  v-model="date"
                  class="form-control"
                  :placeholder="'Date'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>

      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          :disabled="isLoading"
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isLoading"
          @click="handleForm"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BOverlay,
  BButton,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useEditChargeModal from '@/views/components/edit-charge-modal/useEditChargeModal'
import moment from '@/libs/moment'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BOverlay,
    BButton,
    BFormInput,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    chargeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cost: null,
      name: null,
      date: null,
      isLoading: false,
      required,
      timezone: localStorage.getItem('timezone'),
    }
  },
  setup() {
    const {
      fetchCharge,
      editCharge,
    } = useEditChargeModal()

    return {
      fetchCharge,
      editCharge,
    }
  },
  methods: {
    async setModalData() {
      try {
        this.isLoading = true
        const response = await this.fetchCharge(this.chargeId)
        this.name = response.name
        this.cost = response.cost
        this.date = moment(response.date).tz(this.timezone).format('YYYY-MM-DD HH:mm')
      } catch (error) {
        this.closeModal()
      } finally {
        this.isLoading = false
      }
    },
    closeModal() {
      this.cost = null
      this.name = null
      this.date = null
      this.isLoading = false
      this.$refs['edit-charge-modal'].hide()
    },
    handleForm() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs['edit-charge'].validate().then(success => {
          if (success) {
            resolve(true)
            this.updateCharge()
          } else {
            reject()
          }
        })
      })
    },
    async updateCharge() {
      const queryParams = {
        id: this.chargeId,
        charge_cost: this.cost,
        charge_name: this.name,
        date: this.prepareDateToUtc(this.date),
      }
      this.isLoading = true
      await this.editCharge(this.chargeId, queryParams)
        .then(() => {
          this.$emit('success')
          this.closeModal()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    prepareDateToUtc(date) {
      return moment.utc(moment(date).format('YYYY-MM-DDTHH:mmZ')).format('YYYY-MM-DD HH:mm')
    },
  },
}
</script>
