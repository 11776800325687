import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePaymentPopup() {
  // Use toast
  const toast = useToast()

  const sendTransaction = queryParams => axios
    .post('/auth/transactions', queryParams)
    .then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Transaction Success',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
    })
    .catch(error => error.response)

  const fetchCards = queryParams => axios
    .get('/auth/cards', { params: queryParams })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Credit Cards',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const postCard = queryParams => axios
    .post('/auth/cards', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Credit Card has created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error creating new Credit Card',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const replaceCard = (id, queryParams) => axios
    .post(`/auth/cards/${id}/replace`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'The Credit Card successfully replaced',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error replacing the Credit Card',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const deleteCard = ID => axios
    .delete(`/auth/cards/${ID}`)
    .then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting card',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting card',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const postApplicationPayConditions = queryParams => axios
    .post('/auth/application-pay-conditions', queryParams)
    .then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Payment Plan has created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error creating new Payment Plan',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const updatePaymentPlanTransaction = (id, queryParams) => axios
    .put(`/auth/pay-condition-transactions/${id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success updating Payment Plan Transaction',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error editing Payment Plan Transaction',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const payForConditionTransaction = (id, queryParams) => axios
    .post(`/auth/pay-condition-transactions/${id}/pay`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success paid Transaction',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Payment error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const updateTransaction = (id, queryParams) => axios
    .put(`/auth/transactions/${id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success updating Transaction',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
      return response
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error updating Transaction',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const fetchPaymentMethods = queryParams => axios
    .get('/auth/payment-methods', { params: queryParams })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Payment Methods',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const postPaymentMethod = queryParams => axios
    .post('/auth/payment-methods', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Payment Method has created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error creating new Payment Method',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const deletePaymentMethod = ID => axios
    .delete(`/auth/payment-methods/${ID}`)
    .then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting Payment Method',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting Payment Method',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const fetchSessions = queryParams => axios
    .get('auth/sessions', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Sessions',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  return {
    sendTransaction,
    postApplicationPayConditions,
    fetchCards,
    postCard,
    replaceCard,
    deleteCard,
    updatePaymentPlanTransaction,
    payForConditionTransaction,
    updateTransaction,
    fetchPaymentMethods,
    postPaymentMethod,
    deletePaymentMethod,
    fetchSessions,
  }
}
