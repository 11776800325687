<template>
  <b-link
    class="mr-2"
    style="textDecoration: underline"
    @click="loginAs(userId, userRole)"
  >
    Login As This User
    <feather-icon
      icon="LogInIcon"
      size="21"
    />
  </b-link>
</template>

<script>
import {
  BLink,
} from 'bootstrap-vue'
import { loginAs } from '@core/mixins/loginAs'

export default {
  name: 'LoginAsUser',
  components: {
    BLink,
  },
  mixins: [loginAs],
  props: {
    userId: {
      type: Number,
      required: true,
    },
    userRole: {
      type: String,
      required: true,
    },
  },
}
</script>

<style>

</style>
