<template>
  <b-row>
    <b-col
      md="6"
    >
      <!-- Charge details tables -->
      <b-table
        :items="paymentsList"
        :fields="paymentsListColumns"
        primary-key="id"
        show-empty
        empty-text="No transactions"
        class="position-relative"
      >

        <!-- Column: Amount -->
        <template #cell(amount)="data">
          <b>{{ data.item.paid_sum | fixAmount }}</b>
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.value)}`"
          >
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="checkIsPaymentEditable(data.item)"
              @click="showEditPaymentModal(data.item)"
            >
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="checkIsPaymentRefundable(data.item)"
              @click="showPaymentRefundModal(data.item)"
            >
              <span>Refund</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.status.toLowerCase() == 'payment failed'"
              @click="transactionRetry(data.item.id)"
            >
              <span>Retry Payment</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isAvailableToPause(data.item)"
              @click="showHoldPayments(data.item)"
            >
              <span>Pause Payment</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isAvailableToResume(data.item)"
              @click="showResumePayments(data.item)"
            >
              <span>Resume Payment</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="checkIsPaymentDeletable(data.item)"
              @click="openDeleteConfirmation(data.item)"
            >
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <!-- Footer: Total -->
        <template
          v-if="paymentsList.length > 0"
          #bottom-row
        >
          <td class="bg-light">
            Total
          </td>
          <td class="bg-light" />
          <td class="bg-light" />
          <td class="bg-light">
            <b>{{ totalPaidTransactions(paymentsList) | fixAmount }}</b>
          </td>
        </template>
      </b-table>
    </b-col>

    <b-col
      v-if="scheduledPaymentsList.length"
      md="6"
    >
      <b-table
        :items="scheduledPaymentsList"
        :fields="scheduledPaymentsListColumns"
        primary-key="id"
        show-empty
        empty-text="No transactions"
        class="position-relative"
      >

        <!-- Column: Amount -->
        <template #cell(amount)="data">
          <b>{{ data.item.amount | fixAmount }}</b>
        </template>

        <!-- Column: Date -->
        <template #cell(payment_date)="data">
          <span :id="data.item.id">{{ getPayDate(data.item) | shortDate }}</span>
          <b-tooltip
            triggers="hover"
            :target="data.item.id"
            :delay="{ show: 100, hide: 50 }"
          >
            {{ getPayDate(data.item) | longDate }}
          </b-tooltip>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="checkIsPaymentEditable(data.item)"
              @click="showEditPaymentModal(data.item)"
            >
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.model_type == 'Payment' && ['completed', 'partial refunded', 'added to wallet'].includes(data.item.status.toLowerCase())"
              @click="showPaymentRefundModal(data.item)"
            >
              <span>Refund</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.status.toLowerCase() == 'payment failed'"
              @click="transactionRetry(data.item.id)"
            >
              <span>Retry Payment</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isAvailableToPause(data.item)"
              @click="showHoldPayments(data.item)"
            >
              <span>Pause Payment</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isAvailableToResume(data.item)"
              @click="showResumePayments(data.item)"
            >
              <span>Resume Payment</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="checkIsPaymentDeletable(data.item)"
              @click="openDeleteConfirmation(data.item)"
            >
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <!-- Footer: Total -->
        <template
          v-if="chargeDetails.length > 0"
          #bottom-row
        >
          <td class="bg-light">
            Total
          </td>
          <td class="bg-light" />
          <td class="bg-light" />
          <td class="bg-light">
            <b>{{ totalPaidTransactions(scheduledPaymentsList) | fixAmount }}</b>
          </td>
        </template>
      </b-table>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BTable, BBadge,
  BDropdown, BDropdownItem, BTooltip,
} from 'bootstrap-vue'
import { filters } from '@core/mixins/filters'
import { transactionsMixin } from '@/views/admin/transactions-v2/mixins/transactionsMixin'

export default {
  name: 'ChargeDetailsTable',
  components: {
    BRow,
    BCol,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BTooltip,
  },
  mixins: [filters, transactionsMixin],
  props: {
    chargeDetails: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      paymentsListColumns: [
        { key: 'description', label: 'Payments made' },
        { key: 'amount', label: 'Amount' },
        { key: 'status', label: 'Status' },
        { key: 'actions' },
      ],
      scheduledPaymentsListColumns: [
        { key: 'description', label: 'Payments scheduled' },
        { key: 'amount', label: 'Amount' },
        { key: 'payment_date', label: 'Pay Date' },
        { key: 'actions' },
      ],
      paymentsList: [],
      scheduledPaymentsList: [],
    }
  },
  created() {
    this.setPaymentsLists()
  },
  methods: {
    setPaymentsLists() {
      this.chargeDetails.forEach(item => {
        if (item.status.toLowerCase().includes('pending')) {
          this.scheduledPaymentsList.push(item)
        } else {
          this.paymentsList.push(item)
        }
      })
    },
    totalPaidTransactions(transactions) {
      return transactions.reduce((acc, curItem) => acc + Number(curItem.paid_sum), 0)
    },
  },
}
</script>

<style>

</style>
