<template>
  <b-modal
    id="hold-payments"
    ref="hold-payments"
    title="Hold Payments"
    centered
    no-close-on-backdrop
    ok-title="Ok"
    :ok-disabled="isLoading"
    ok-only
    size="sm"
    class="p-0"
    @ok="handleForm"
    @hidden="resetModal"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <validation-observer
        ref="holdPayments"
      >
        <b-row>
          <b-col
            sm="6"
          >
            <b-card
              class="mb-1"
            >
              <b-form-radio
                v-model="holdType"
                name="hold-type"
                value="pause"
              >
                Pause
              </b-form-radio>
            </b-card>
          </b-col>

          <b-col
            sm="6"
          >
            <b-card
              class="mb-1"
            >
              <b-form-radio
                v-model="holdType"
                name="hold-type"
                value="pause_until"
              >
                Pause Until
              </b-form-radio>
            </b-card>
          </b-col>
        </b-row>

        <template
          v-if="holdType == 'pause_until'"
        >
          <validation-provider
            #default="{ errors }"
            name="Pause Until"
            rules="required"
          >
            <b-form-group
              label="Pause Until"
            >
              <flat-pickr
                v-model="pauseUntil"
                class="form-control"
                placeholder="Select Date"
                :config="{
                  enable: [enableDates]
                }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </template>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal, BOverlay, BCard, BRow, BCol, BFormGroup, BFormRadio,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import flatPickr from 'vue-flatpickr-component'
import useHoldPaymentModal from '@/views/components/hold-payments-modal/useHoldPaymentModal'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BModal,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,

    flatPickr,
  },
  props: {
    holdPaymentOptionItem: {
      type: Object,
      default: () => {},
    },
    holdPayConditionTransaction: {
      type: Object,
      default: () => {},
    },
    applicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      holdType: 'pause',
      pauseUntil: null,
      required,
    }
  },
  setup() {
    const {
      updateApplicationPaymentOption,
      updateApplicationPayConditionTransaction,
    } = useHoldPaymentModal()

    return {
      updateApplicationPaymentOption,
      updateApplicationPayConditionTransaction,
    }
  },
  methods: {
    resetModal() {
      this.holdType = 'pause'
      this.pauseUntil = null
    },
    enableDates(date) {
      if (this.holdPayConditionTransaction?.id) {
        return date >= new Date(this.holdPayConditionTransaction.pay_date)
      }
      if (this.holdPaymentOptionItem?.id) {
        return date >= new Date(this.holdPaymentOptionItem.start_date)
      }
      return date >= new Date()
    },
    async handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      await this.$refs.holdPayments.validate()
        .then(success => {
          if (success) {
            if (this.holdPaymentOptionItem?.id) {
              this.pauseApplicationPaymentOption()
            }
            if (this.holdPayConditionTransaction?.id) {
              this.pauseApplicationPayConditionTransaction()
            }
          }
        })
    },
    async pauseApplicationPaymentOption() {
      const queryParams = {
        application_id: this.applicationId,
        ...this.holdPaymentOptionItem,
      }
      if (this.holdType === 'pause_until') {
        queryParams.start_date = this.pauseUntil
      } else {
        queryParams.is_active = 0
        queryParams.start_date = null
      }

      this.isLoading = true
      this.updateApplicationPaymentOption(queryParams).then(() => {
        this.$emit('updateData')
        this.$bvModal.hide('hold-payments')
      }).finally(() => {
        this.isLoading = false
      })
    },
    async pauseApplicationPayConditionTransaction() {
      const queryParams = {
        application_id: this.applicationId,
        id: this.holdPayConditionTransaction.id,
        status: 'Paused',
        is_active: this.holdType === 'pause_until' ? 1 : 0,
      }
      if (this.holdType === 'pause_until') {
        queryParams.pay_date = this.pauseUntil
      }

      this.isLoading = true
      this.updateApplicationPayConditionTransaction(queryParams).then(() => {
        this.$emit('updateData')
        this.$bvModal.hide('hold-payments')
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
